import React from 'react'
import { API, graphqlOperation, Auth, Storage } from 'aws-amplify'
import { createSetting, updateSetting, createUser, updateUser, deleteUser, deleteOrganization, deleteSetting, updateMember, createMember, updateOrganization } from '../graphql/mutations'
import { listOrganizations, settingsByOrganizationId, userByUserName, settingsByMakeAWishURL, listUsers, getOrganization, membersByOrganizationId } from '../graphql/queries'
import AWS from 'aws-sdk'
import awsconfig from '../aws-exports'

// Support libraries
import { v4 as uuid } from 'uuid'
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { CSVLink } from "react-csv";

//UI
import { Switch, TextInput, Tooltip, Button, SavedIcon, Pane, Tablist, Tab, HelpIcon, Checkbox, Spinner, ArrowTopRightIcon, IconButton, ClipboardIcon, Table, AddIcon, toaster, Dialog } from 'evergreen-ui'
import '../Styles/tables.css'
import '../Styles/progressbar.css'
import logo from '../wishwellbyonegift.svg'

// Bug tracking
import Bugsnag from '@bugsnag/js'

// Analytics
import mixpanel from 'mixpanel-browser';
import { remove } from 'jszip'

class Settings extends React.Component {
    constructor(props) {
        super(props)
        this.logoInput = React.createRef();
        this.membersImport = React.createRef();
        this.tabs = ['Organization', 'Users'];
        // Get backend environment
        const envStart = awsconfig.aws_cloud_logic_custom[0].endpoint.lastIndexOf('/');
        const env = awsconfig.aws_cloud_logic_custom[0].endpoint.slice(envStart+1);
        this.state = {
            settingsLoaded: false,
            selectedIndex: 0,
            eventOptions: [
                { value: 'Birthdays', label: 'Birthdays' },
                { value: 'Anniversaries', label: 'Anniversaries' },
                { value: 'Grandchild Birthday', label: 'Grandchild Birthday' },
            ],
            user: {
                id: '',
                userName: '',
                permissions: [],
            },
            newUser: {
                id: '',
                userName: '',
                permissions: [],
            },
            env: env,
            users: [],
            permissionOptions: [
                { value: 'Admin', label: 'Admin' },
                { value: 'Members', label: 'Members' },
                { value: 'Orders', label: 'Orders' },
                { value: 'Generation', label: 'Generation' },
                { value: 'Reports', label: 'Reports' },
            ],
            orgTypes: [
                { value: 'Nonprofit', label: 'Nonprofit' },
                { value: 'Place of Worship', label: 'Place of Worship' },
                { value: 'Place of Work', label: 'Place of Work' },
                { value: 'Community', label: 'Community' },
                { value: 'School', label: 'School' },
                { value: 'Other', label: 'Other' },
            ],
            membersForCSV: [],
            originalMakeAWishURL: '',
            saveUserModalShown: false,
            saveUserLoading: false,
            createUpdateUser: 'Create',
            savePayoutInfoModalShow: false,
            savePayoutInfoLoading: false,
            payoutFieldsDisabled: false,
            enableWishWellModalShown: false,
            enableWishWellLoading: false,
          }

        this.componentDidMount = this.componentDidMount.bind(this)
        this.loadPage = this.loadPage.bind(this)
        this.saveTimerFunction = this.saveTimerFunction.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleUploadButtonClick = this.handleUploadButtonClick.bind(this)
        this.uploadLogo = this.uploadLogo.bind(this)
        this.importMembers = this.importMembers.bind(this)
        this.saveSettings = this.saveSettings.bind(this)
        this.savePayoutInfo = this.savePayoutInfo.bind(this)
        this.generatePassword = this.generatePassword.bind(this)
        this.verifyEmail = this.verifyEmail.bind(this)
        this.saveUser = this.saveUser.bind(this)
        this.subscribeUserToHubspot = this.subscribeUserToHubspot.bind(this);
        this.toggleFullAuto = this.toggleFullAuto.bind(this)
        this.deleteDummyUser = this.deleteDummyUser.bind(this)
        this.setupStripe = this.setupStripe.bind(this)
        this.changeOrg = this.changeOrg.bind(this)
        this.changeOrgSave = this.changeOrgSave.bind(this)
    }

    async componentDidMount() {
        // Tracking
        mixpanel.track('Settings page visited');
        const event = 'new load'
        this.loadPage(event)
    }

    loadPage = async (event) => {
        try {
            const countryNames = [
                'Australia',
                'Austria',
                'Belgium',
                'Bulgaria',
                'Canada',
                'Croatia',
                'Cyprus',
                'Czech Republic',
                'Denmark',
                'Estonia',
                'Finland',
                'France',
                'Germany',
                'Gibraltar',
                'Greece',
                'Hong Kong',
                'Hungary',
                'Ireland',
                'Italy',
                'Japan',
                'Latvia',
                'Liechtenstein',
                'Lithuania',
                'Luxembourg',
                'Malta',
                'Mexico',
                'Netherlands',
                'New Zealand',
                'Norway',
                'Poland',
                'Portugal',
                'Romania',
                'Singapore',
                'Slovakia',
                'Slovenia',
                'Spain',
                'Sweden',
                'Switzerland',
                'Thailand',
                'United Arab Emirates',
                'United Kingdom',
                'United States'
            ];

            const allCountryNames = countryNames.map(country => ({ value: country, label: country }));

            this.setState({ countries: allCountryNames });

            // Check for existing setting record
            const user_info = await Auth.currentUserInfo()
            if (!user_info || !user_info.username) {
                this.loadPage()
                return
            }
            const username = user_info.username
            const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
            var orgId = user.data.userByUserName.items[0].organizationId
            const settings_record = await API.graphql(graphqlOperation(settingsByOrganizationId, { organizationId: orgId }))
            const orgSettings = settings_record.data.settingsByOrganizationId.items[0]
            var updatedSettings = {}
            updatedSettings.id = orgSettings.id
            updatedSettings.chapterName = orgSettings.chapterName
            updatedSettings.orgEmail = orgSettings.orgEmail
            updatedSettings.minWishPrice = orgSettings.minWishPrice
            updatedSettings.events = orgSettings.events.map(str => JSON.parse(`{${str.replace("{", "").replace("}", "").replace(/(\w+)\s*=\s*([^,}]+)/g, '"$1":"$2"')}}`))

            updatedSettings.customFields = orgSettings.customFields.map(str => JSON.parse(`{${str.replace("{", "").replace("}", "").replace(/(\w+)\s*=\s*([^,}]+)/g, '"$1":"$2"')}}`))
            updatedSettings.makeAWishURL = orgSettings.makeAWishURL
            updatedSettings.defaultWishers = orgSettings.defaultWishers.map(str => JSON.parse(`{${str.replace("{", "").replace("}", "").replace(/(\w+)\s*=\s*([^,}]+)/g, '"$1":"$2"')}}`))
            updatedSettings.envelopeReturnName = orgSettings.envelopeReturnName
            updatedSettings.envelopeReturnAddressLine1 = orgSettings.envelopeReturnAddressLine1
            updatedSettings.envelopeReturnAddressLine2 = orgSettings.envelopeReturnAddressLine2
            updatedSettings.logo = orgSettings.logo
            updatedSettings.brandColor = orgSettings.brandColor
            updatedSettings.taxNumber = orgSettings.taxNumber
            updatedSettings.orgType = JSON.parse(`{${orgSettings.orgType.replace("{", "").replace("}", "").replace(/(\w+)\s*=\s*([^,}]+)/g, '"$1":"$2"')}}`)
            updatedSettings.giveLocal = orgSettings.giveLocal
            updatedSettings.paymentType = orgSettings.paymentType
            updatedSettings.paymentId = orgSettings.paymentId
            updatedSettings.bankInfo = orgSettings.bankInfo
            updatedSettings.country = JSON.parse(`{${orgSettings.country.replace("{", "").replace("}", "").replace(/(\w+)\s*=\s*([^,}]+)/g, '"$1":"$2"')}}`)
            updatedSettings.referrer = orgSettings.referrer
            updatedSettings.referralCredit = orgSettings.referralCredit
            updatedSettings.fullAuto = orgSettings.fullAuto ? orgSettings.fullAuto : false
            updatedSettings.birthdayBooster = orgSettings.birthdayBooster ? orgSettings.birthdayBooster : false
            updatedSettings.organizationId = orgId
            updatedSettings.charityPartner = orgSettings.charityPartner ? orgSettings.charityPartner : ""
            updatedSettings.stripeAccountId = orgSettings.stripeAccountId ? orgSettings.stripeAccountId : ""
            updatedSettings.stripeOnboardingComplete = orgSettings.stripeOnboardingComplete ? orgSettings.stripeOnboardingComplete : false

            var isNonProfit = true
            if (updatedSettings.orgType.value === 'Place of Work' ||
            updatedSettings.orgType.value === 'Community' ||
            updatedSettings.orgType.value === 'School' ||
            updatedSettings.orgType.value === 'Other') {
                isNonProfit = false
            }
            this.setState({ nonProfit: isNonProfit })

            // Check for existing setting record
            const organization_return = await API.graphql(graphqlOperation(getOrganization, { id: orgId }))
            this.setState({
                organization: organization_return.data.getOrganization
            })

            // check if stripe account setup
            if (organization_return.data.getOrganization.parentOrgId !== "859d0bb4-21c4-4c0d-9804-126093d78552") {
                const apiName = 'wishwellpayments';
                var path = '/payments/retrieveAccount';
                var myInit = { // OPTIONAL
                    body: {
                        account_id: orgSettings.stripeAccountId
                    },
                    headers: {}, // OPTIONAL
                };
                const stripe = await API.post(apiName, path, myInit);
                if (stripe.body.charges_enabled) {
                    updatedSettings.stripeOnboardingComplete = true
                }
                else {
                    updatedSettings.stripeOnboardingComplete = false
                }
            }

            if (event && !updatedSettings.fullAuto && organization_return.data.getOrganization.parentOrgId !== "859d0bb4-21c4-4c0d-9804-126093d78552") {
                toaster.notify(<div>WishWell isn't enabled yet. Please input all of your organization details and customizations, import your donors/members, add your payout information, and press 'Save Settings' to enable WishWell and start fundraising! <a href="https://onegift.ai/resources/enable-wishwell" target="_blank" rel="noopener noreferrer">Click here for more information</a></div>, {duration: 30})
            }

            // Get users
            var nextToken = null;
            var count = 0
            var filteredList
            var USERS = []
            while (nextToken || count === 0) {
                count = 1
                filteredList = await API.graphql(
                graphqlOperation(listUsers, {
                filter: {
                    organizationId: { eq: orgId }
                },
                limit: 900,
                nextToken:nextToken}))
                nextToken = filteredList.data.listUsers.nextToken
                USERS = USERS.concat(filteredList.data.listUsers.items)
            }
            
            for (var i = 0; i < USERS.length; i++) {
                USERS[i].permissions = USERS[i].permissions.map(str => JSON.parse(`{${str.replace("{", "").replace("}", "").replace(/(\w+)\s*=\s*([^,}]+)/g, '"$1":"$2"')}}`))
            }

            nextToken = null;
            count = 0
            var MEMBERS = []
            
            while (nextToken || count === 0) {
                count = 1
                filteredList = await API.graphql(graphqlOperation(membersByOrganizationId, {
                    organizationId: orgId,
                    limit: 900,
                    nextToken:nextToken
                }))
                    nextToken = filteredList.data.membersByOrganizationId.nextToken
                    MEMBERS = MEMBERS.concat(filteredList.data.membersByOrganizationId.items
                )}
            MEMBERS.sort(function(a, b) {
                var textA = a.lname.toUpperCase();
                var textB = b.lname.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            var membersForCSV = [["First Name*", "Last Name*", "Email*", "Birthday* (MM/DD)"]]

            var activeCount = 0
            for (i = 0; i < MEMBERS.length; i++) {
                if (MEMBERS[i].pactive) {
                    activeCount++
                }
                    membersForCSV.push([MEMBERS[i].fname, MEMBERS[i].lname, MEMBERS[i].email, MEMBERS[i].DOB])
            }    

            // Check if email is verified
            const apiName = 'wishwellapi';
            path = '/wishwellapi/emailVerified';
            myInit = { // OPTIONAL
                body: {
                    orgEmail: updatedSettings.orgEmail
                },
                headers: {}, // OPTIONAL
            };
            const emailVerified = await API.post(apiName, path, myInit);
            console.log('email verified: ' + emailVerified.verified)
            this.setState({ emailVerified: emailVerified.verified })

            // Get currency symbol
            const stripeCountryInfo = [
                {
                  country: 'Australia',
                  code: 'AU',
                  currency: 'AUD',
                  symbol: '$'
                },
                {
                  country: 'Austria',
                  code: 'AT',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Belgium',
                  code: 'BE',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Bulgaria',
                  code: 'BG',
                  currency: 'BGN',
                  symbol: 'лв'
                },
                {
                  country: 'Canada',
                  code: 'CA',
                  currency: 'CAD',
                  symbol: '$'
                },
                {
                  country: 'Croatia',
                  code: 'HR',
                  currency: 'HRK',
                  symbol: 'kn'
                },
                {
                  country: 'Cyprus',
                  code: 'CY',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Czech Republic',
                  code: 'CZ',
                  currency: 'CZK',
                  symbol: 'Kč'
                },
                {
                  country: 'Denmark',
                  code: 'DK',
                  currency: 'DKK',
                  symbol: 'kr'
                },
                {
                  country: 'Estonia',
                  code: 'EE',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Finland',
                  code: 'FI',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'France',
                  code: 'FR',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Germany',
                  code: 'DE',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Gibraltar',
                  code: 'GI',
                  currency: 'GBP',
                  symbol: '£'
                },
                {
                  country: 'Greece',
                  code: 'GR',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Hong Kong',
                  code: 'HK',
                  currency: 'HKD',
                  symbol: 'HK$'
                },
                {
                  country: 'Hungary',
                  code: 'HU',
                  currency: 'HUF',
                  symbol: 'Ft'
                },
                {
                  country: 'Ireland',
                  code: 'IE',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Italy',
                  code: 'IT',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Japan',
                  code: 'JP',
                  currency: 'JPY',
                  symbol: '¥'
                },
                {
                  country: 'Latvia',
                  code: 'LV',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Liechtenstein',
                  code: 'LI',
                  currency: 'CHF',
                  symbol: 'CHF'
                },
                {
                  country: 'Lithuania',
                  code: 'LT',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Luxembourg',
                  code: 'LU',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Malta',
                  code: 'MT',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Mexico',
                  code: 'MX',
                  currency: 'MXN',
                  symbol: '$'
                },
                {
                  country: 'Netherlands',
                  code: 'NL',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'New Zealand',
                  code: 'NZ',
                  currency: 'NZD',
                  symbol: '$'
                },
                {
                  country: 'Norway',
                  code: 'NO',
                  currency: 'NOK',
                  symbol: 'kr'
                },
                {
                  country: 'Poland',
                  code: 'PL',
                  currency: 'PLN',
                  symbol: 'zł'
                },
                {
                  country: 'Portugal',
                  code: 'PT',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Romania',
                  code: 'RO',
                  currency: 'RON',
                  symbol: 'lei'
                },
                {
                  country: 'Singapore',
                  code: 'SG',
                  currency: 'SGD',
                  symbol: '$'
                },
                {
                  country: 'Slovakia',
                  code: 'SK',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Slovenia',
                  code: 'SI',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Spain',
                  code: 'ES',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Sweden',
                  code: 'SE',
                  currency: 'SEK',
                  symbol: 'kr'
                },
                {
                  country: 'Switzerland',
                  code: 'CH',
                  currency: 'CHF',
                  symbol: 'CHF'
                },
                {
                  country: 'Thailand',
                  code: 'TH',
                  currency: 'THB',
                  symbol: '฿'
                },
                {
                  country: 'United Arab Emirates',
                  code: 'AE',
                  currency: 'AED',
                  symbol: 'د.إ'
                },
                {
                  country: 'United Kingdom',
                  code: 'GB',
                  currency: 'GBP',
                  symbol: '£'
                },
                {
                  country: 'United States',
                  code: 'US',
                  currency: 'USD',
                  symbol: '$'
                }
            ];
            const countryMap = updatedSettings.country
            const countryInfo = stripeCountryInfo.find(info => info.country === countryMap.value);
            const countryCurrency = countryInfo ? countryInfo.currency : 'usd';

            const currencySymbol = new Intl.NumberFormat('en-US', { style: 'currency', currency: countryCurrency }).formatToParts()[0].value;

            this.setState({
                members: MEMBERS,
                settings: updatedSettings, 
                users: USERS,
                originalMakeAWishURL: orgSettings.makeAWishURL,
                activeCount: activeCount,
                currencySymbol: currencySymbol,
                user: user.data.userByUserName.items[0],
                membersForCSV: membersForCSV,
            })

            await API.graphql(graphqlOperation(updateSetting, {input: updatedSettings}))

            // Obfuscate bank info
            // TODO: obfuscate bankInfo via graphql (server-side); not needed anymore with Stripe
            var payoutFieldsDisabled = false
            if (orgSettings.bankInfo[0] !== '') {
                payoutFieldsDisabled = true
                orgSettings.bankInfo[1] = '************' + orgSettings.bankInfo[1].slice(-4)
                orgSettings.bankInfo[2] = '************' + orgSettings.bankInfo[2].slice(-4)
            }
            updatedSettings.bankInfo = orgSettings.bankInfo

            this.setState({
                settings: updatedSettings, 
                users: USERS,
                originalMakeAWishURL: orgSettings.makeAWishURL,
                payoutFieldsDisabled: payoutFieldsDisabled,
                settingsLoaded: true
            })
        } catch (error) {
            Bugsnag.notify(error);
            console.log(error)
        }
    }

    handleUploadButtonClick(event) {
        // Trigger a click event on the file input field
        // TODO: fix bug where you can't import a second time without refreshing
        if (event.currentTarget.innerHTML === 'Import Donors') {
            this.membersImport.current.click();
        }
        else if (event.currentTarget.innerHTML === 'Upload Logo') {
            this.logoInput.current.click();
        }
    };

    uploadLogo = async (event) => {
        const file = event.target.files[0];
        await this.saveSettings('supressMessage')
        if (file.type === 'image/png' || file.type === 'image/jpeg') {
            try {
                this.setState({ uploadingLogo: true })
                const result = await Storage.put(file.name, file);
                // TODO: don't need objectURL anymore
                const objectURL = this.state.env === 'dev' ? "https://s3.amazonaws.com/wishwell-etl-data94437-dev/public/" + result.key : "https://s3.amazonaws.com/wishwell-etl-data134950-master/public/" + result.key
                const logo = [result.key, objectURL]
                this.setState({
                    settings: {
                        ...this.state.settings,
                        logo: logo
                    }
                })
                await API.graphql(graphqlOperation(updateSetting, {input: this.state.settings}))
                mixpanel.track('Custom logo uploaded');
                this.setState({ uploadingLogo: false })
                toaster.success('Logo uploaded successfully')
                this.props.reloadMain();
            } catch (error) {
                console.log(error)
                Bugsnag.notify(error)
            }
        } else {
            toaster.warning('Please select a PNG or JPEG image file.', {duration: 5})
        }
    };

    importMembers = async (event) => {
        await this.saveSettings('supressMessage')
        try {   
            const files = event.target.files;
            if (files.length === 0) {
              return; // Exit the function if no file was selected
            }
            const file = event.target.files[0];
            if (file.type === 'text/csv') {
                this.setState({ 
                    importingMembers: true,
                    numberMembersImported: 0
                })
                const reader = new FileReader();

                const promise = new Promise((resolve, reject) => {
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.onerror = () => {
                    reject(reader.error);
                };
                });
                reader.readAsText(file);
                const contents = await promise;
                const members = contents.split('\n').slice(1).reduce((acc, line) => {
                    const fields = line.split(',');
                    // if (fields.some((field) => !field.trim())) {
                    //   // Skip lines with empty fields
                    //   return acc;
                    // }
                    const count = (line.match(/,,/g) || []).length;
                    if (count > 0) {
                      toaster.warning('Please make sure your CSV file has 4 columns for every member: First Name, Last Name, Email, and Birthday.', {duration: 5})
                      throw new Error('Your import file does not have values for all 4 columns for all import entries. Please input values for all 4 columns for all import entries and try again.')
                    }
                    const [fname, lname, email, birthday] = fields;
                    // check if email field is valid
                    if (!email.includes('@')) {
                        toaster.warning('The email field is required for all import entries.', {duration: 5})
                        throw new Error('Your import file does not have a valid email address for all import entries. Please input a valid email address for all import entries and try again.')
                      }
                    const date = birthday && !isNaN(Date.parse(birthday)) ? new Date(birthday) : new Date();
                    const year = date.getFullYear();
                    const month = date.getMonth() + 1;
                    const day = date.getDate();
                    const DOB = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
                    acc.push({ fname, lname, email, DOB});
                    return acc;
                }, []);
                // Get next pnumber
                var highestPnumber = this.state.members.reduce((acc, member) => {
                    return Math.max(acc, parseInt(member.pnumber));
                }, 0);

                // [updated, created] members
                var membersImported = [0,0]
                for (const member of members) {
                    member.pactive = true
                    const existingMember = this.state.members.find((m) => m.email === member.email);
                    if (existingMember) {
                        // Update existing member
                        membersImported[0]++;
                        member.id = this.state.members.find((m) => m.email === member.email).id
                        member.pnumber = this.state.members.find((m) => m.email === member.email).pnumber // Prevent pnumber from changing
                        await API.graphql(graphqlOperation(updateMember, {input: member}))
                            .catch((err) => console.log(err))
                    } else {
                        // Create new member
                        membersImported[1]++;
                        member.id = uuid()
                        member.pnumber = highestPnumber + 1
                        member.organizationId = this.state.settings.organizationId
                        member.ecards = true
                        member.eforms = true
                        member.birthdayBooster = true
                        member.lifeCycle = 'Registered'
                        await API.graphql(graphqlOperation(createMember, {input: member}))
                        highestPnumber++
                    }
                    this.setState({ numberMembersImported: this.state.numberMembersImported + 1 })
                }
                mixpanel.track('Members imported successfully.');
                this.setState({ importingMembers: false })
                toaster.success('Member import completed. ' + membersImported[1] + ' members created and ' + membersImported[0] + ' members updated.', {duration: 5});
            } 
            else {
                toaster.warning('Please select a CSV file.', {duration: 5})
                return
            }
            
        } catch (error) {
            toaster.danger('Error importing members. ' + error, {duration: 5})
            this.setState({ settingsLoaded: false })
            if (error.message !== 'Your import file does not have values for all 4 columns for all import entries. Please input values for all 4 columns for all import entries and try again.') {
                Bugsnag.notify(error);
            }
        }
        this.loadPage()
    }

    timer = null

    saveTimerFunction() {
        // Clear the previous timer if it exists
        if (this.timer) {
            clearTimeout(this.timer);
        }
  
        // Start a new timer
        this.timer = setTimeout(() => {
            this.saveSettings();
        }, 3000);  // 3000 milliseconds = 3 seconds
    }

    handleChange(newValue, actionMeta, id) {
        var updatedSettings = this.state.settings
        var updatedUser = this.state.user
        var updatedNewUser = this.state.newUser
        var updatedBankInfo = this.state.settings.bankInfo
        if (newValue.target) {
            if (newValue.target.id.startsWith('user.')) {
                const target = newValue.target
                const field = target.name
                const value = target.value
                updatedUser[field] = value
            }
            if (newValue.target.id.startsWith('newUser.')) {
                const target = newValue.target
                const field = target.name
                const value = target.value
                updatedNewUser[field] = value
            }
            if (newValue.target.id.startsWith('bankInfo.')) {
                const target = newValue.target
                const fieldValue = target.name
                const field = fieldValue === 'accountName' ? 0 : fieldValue === 'accountNumber' ? 1 : 2
                const value = target.value
                updatedBankInfo[field] = value
            }
            else {
                const target = newValue.target
                const field = target.name
                const value = target.value
                updatedSettings[field] = value
                this.saveTimerFunction()
            }
        }
        else {
            if (actionMeta.action === "remove-value" && actionMeta.removedValue.value === 'Birthdays') {
                toaster.warning('You cannot remove the Birthdays event', {duration: 5})
                return
            }
            if (actionMeta.name === 'permissions') {
                updatedNewUser.permissions = newValue
            }
            if (actionMeta.action === "remove-value" && actionMeta.removedValue.value === 'Super Admin') {
                        toaster.warning('You cannot remove the Super Admin role. Contact support to transfer the role to another user.', {duration: 5})
                return
            }
            else {
                updatedSettings[id] = newValue
                if (id === 'orgType') {
                    var isNonProfit = true
                    if (newValue.value === 'Place of Work' ||
                    newValue.value === 'Community' ||
                    newValue.value === 'School' ||
                    newValue.value === 'Other') {
                        isNonProfit = false
                    }
                    this.setState({ nonProfit: isNonProfit })
                }
            }
        }
        this.setState({
            settings: updatedSettings,
            user: updatedUser
        })
    }

    verifyEmail = async () => {
        this.setState({ verifyingEmail: true })
        await this.saveSettings('supressMessage')

        const email = this.state.settings.orgEmail

        // Verify email
        const apiName = 'wishwellapi';
        var path = '/wishwellapi/verifyEmail';
        var myInit = { // OPTIONAL
            body: {
                email: email
            },
            headers: {}, // OPTIONAL
        };
        const verifyEmailResponse = await API.post(apiName, path, myInit);

        if (verifyEmailResponse.result) {
            toaster.success(`You will receive an email from Amazon Web Services with a link to verify that you own ${email}. Please click the link in the email to verify your email address, and the verification process will be complete! Make sure to check your spam/junk folder if the verification email hasn't come through within 5 minutes.`, {duration: 5})
        }
        else {
            toaster.warning('Email verification failed. Please try again or contact support.', {duration: 5})
        }
        mixpanel.track('orgEmail verification process started');
        this.setState({ verifyingEmail: false })
    }

    saveUser = async (action) => {
        var user = this.state.newUser
        if (!user.userName || !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(user.userName)) {
            toaster.warning('Please enter a valid email address as the username', {duration: 5})
            this.setState({ saveUserLoading: false })
            return
        }
        if (action === 'Create') {
            // Check if user already exists
            const userExists = await API.graphql(graphqlOperation(userByUserName, { userName: user.userName.toLowerCase() }))
            if (userExists.data.userByUserName.items.length > 0) {
                toaster.warning('This email already belongs to a WishWell user. If they aren\'t listed in the Users tab, please use a different email for them.', {duration: 5})
                this.setState({ saveUserLoading: false })
                return
            }
            try {
                user.id = uuid()
                user.NPS = '{date=' + new Date().toISOString().split('T')[0] + ', score=none}'
                user.lifeCycle = 'Customer'
                user.organizationId = this.state.settings.organizationId
                user.userName = user.userName.toLowerCase()

                // set permissions as constant order
                const permissionsOrder = ["Super Admin", "Admin", "Members", "Orders", "Generation", "Reports"]
                const sortedPermissions = user.permissions.sort((a, b) => {
                    const aIndex = permissionsOrder.indexOf(a.label);
                    const bIndex = permissionsOrder.indexOf(b.label);
                    return aIndex - bIndex;
                });
                user.permissions = sortedPermissions

                const tempPassword = await this.generatePassword(8);
                await Auth.signUp({
                    username: user.userName,
                    password: tempPassword,
                    attributes: {
                      email: user.userName,
                      'custom:orgName': this.state.settings.chapterName,
                      'custom:userType': 'userCreated',
                      'custom:initialPassword': tempPassword
                    }
                })
                await API.graphql(graphqlOperation(createUser, {input: user}))
            
                // Set user to Admin group
                const apiName = 'wishwellapi';
                var path = '/wishwellapi/addUserToAdminGroup';
                var myInit = { // OPTIONAL
                    body: {
                        userName: user.userName
                    },
                    headers: {}, // OPTIONAL
                };
                await API.post(apiName, path, myInit)

                this.subscribeUserToHubspot(user.userName, this.state.settings.chapterName, this.state.settings.orgType.value)
                
                // Wait for 5 seconds
                await new Promise(resolve => setTimeout(resolve, 3000));

                toaster.success('User created and invitation email sent!')
                mixpanel.track('User created');
                this.setState({ 
                    saveUserModalShown: false, 
                    saveUserLoading: false,             
                    newUser: {
                        id: '',
                        userName: '',
                        permissions: [],
                    }, 
                })
                this.loadPage()
            } catch (error) {
                if (error.code === 'UsernameExistsException') {
                    toaster.warning('This email is already in use for another WishWell account. Please use a different email to register your organization.');
                  } else {
                    Bugsnag.notify(error);
                    toaster.danger('Operation failed: ' + error, {duration: 5})
                  }
            }
        }
        else if (action === 'Update') {
            // Check if user already exists
            const userExists = await API.graphql(graphqlOperation(userByUserName, { userName: user.userName }))
            if (userExists.data.userByUserName.items.length > 0) {
                try {
                    user.id = userExists.data.userByUserName.items[0].id
                    user.NPS = userExists.data.userByUserName.items[0].NPS
                    user.lifeCycle = userExists.data.userByUserName.items[0].lifeCycle
                    user.organizationId = userExists.data.userByUserName.items[0].organizationId

                    // set permissions as constant order
                    const permissionsOrder = ["Super Admin", "Admin", "Members", "Orders", "Generation", "Reports"]
                    const sortedPermissions = user.permissions.sort((a, b) => {
                        const aIndex = permissionsOrder.indexOf(a.label);
                        const bIndex = permissionsOrder.indexOf(b.label);
                        return aIndex - bIndex;
                    });
                    user.permissions = sortedPermissions

                    await API.graphql(graphqlOperation(updateUser, {input: user}))
                    mixpanel.track('User updated');
                    toaster.success('User updated')
                    this.setState({ 
                        saveUserModalShown: false, 
                        saveUserLoading: false,             
                        newUser: {
                            id: '',
                            userName: '',
                            permissions: [],
                        }, 
                    })
                    this.loadPage()
                } catch (error) {
                    Bugsnag.notify(error);
                    toaster.danger('Operation failed: ' + error, {duration: 5})
                }
            }
            else {
                toaster.warning('This user does not exist. Please create them instead or contact support.', {duration: 5})
                return
            }
        }
        else if (action === 'Delete') {
            if (this.state.newUser.permissions.some(permission => permission.value === "Super Admin")) {
                toaster.danger('You cannot delete a Super Admin, please contact support to transfer the role to another user before deleting this user.', {duration: 5})
                return
            }
            if (window.confirm('Are you sure you want to delete this user?')) {
                // Check if user already exists
                const userExists = await API.graphql(graphqlOperation(userByUserName, { userName: user.userName }))
                if (userExists.data.userByUserName.items.length > 0) {
                    try {
                        user.id = userExists.data.userByUserName.items[0].id

                        var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

                        const userPoolId = this.state.env === 'dev' ? process.env.REACT_APP_USER_POOL_ID_DEV : process.env.REACT_APP_USER_POOL_ID_PROD
                        
                        const deleteParams = {
                            UserPoolId: userPoolId,
                            Username: user.userName
                        };
                        
                        await cognitoidentityserviceprovider.adminDeleteUser(deleteParams, function(err, data) {
                            if (err) console.log(err, err.stack); // an error occurred
                            else     console.log(data);           // successful response
                        });
                        await API.graphql(graphqlOperation(deleteUser, {input: { id: user.id, }}))
                        mixpanel.track('User deleted');
                        toaster.success('User deleted')
                        this.setState({ 
                            saveUserModalShown: false, 
                            saveUserLoading: false,             
                            newUser: {
                                id: '',
                                userName: '',
                                permissions: [],
                            }, 
                        })
                        this.loadPage()
                    } catch (error) {
                        Bugsnag.notify(error);
                        toaster.danger('Operation failed: ' + error, {duration: 5})
                    }
                }
                else {
                    toaster.warning('This user does not exist. Please create them instead or contact support.', {duration: 5})
                    return
                }
            }
        }
    }

    subscribeUserToHubspot(email, company, sector) {
        const portalId = '24356242';
        const formGuid = 'eaf1ea56-de2c-4649-ae4b-73709f4d8f23';

        const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`;

        const data = {
            submittedAt: new Date().getTime(),
            fields: [
                {
                    name: 'email',
                    value: email
                },
                {
                  name: 'company',
                  value: company
                },
                {
                  name: 'industry',
                  value: sector
                },
            ]
        };

        fetch(url, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            console.log('Submission successful', data);
            // Do something with response data
        })
        .catch(error => {
            console.error('There was an error!', error);
            Bugsnag.notify(error)
        });
    }

    setupStripe = async (action) => {
        this.setState({ setupStripeLoading: true })
        if (action === 'create') {
            try {
                const apiName = 'wishwellpayments';
                var path = '/payments/createAccountLink';
                var myInit = { // OPTIONAL
                    body: {
                        account_id: this.state.settings.stripeAccountId,
                        type: this.state.settings.stripeOnboardingComplete ? "account_update" : "account_onboarding",
                    },
                    headers: {}, // OPTIONAL
                };
                const stripe = await API.post(apiName, path, myInit);
                window.location.href = stripe.body.url;
            } catch (error) {
                console.log(error)
                Bugsnag.notify(error)
            }
        }
        else {
            try {
                const apiName = 'wishwellpayments';
                var path = '/payments/createLoginLink';
                var myInit = { // OPTIONAL
                    body: {
                        account_id: this.state.settings.stripeAccountId,
                    },
                    headers: {}, // OPTIONAL
                };
                const stripe = await API.post(apiName, path, myInit);
                window.open(stripe.body.url, '_blank');
            } catch (error) {
                console.log(error)
                Bugsnag.notify(error)
            }
        }
        this.setState({ setupStripeLoading: false })
    }

    // TODO: this isn't necessary after stripe connect implementation
    savePayoutInfo = async (action) => {
        await this.saveSettings('supressMessage')
        if (this.state.settings.bankInfo[0] === '' || this.state.settings.bankInfo[1].includes('*')) {
            toaster.warning('You must first enter in or change your payout details before saving.', {duration: 5})
            this.setState({ savePayoutInfoLoading: false })
            return
        }
        try {
            await API.graphql(graphqlOperation(updateSetting, {input: this.state.settings}))
            mixpanel.track('Payout info saved');
            toaster.success('Payout info saved', {duration: 5})
            this.setState({ savePayoutInfoModalShow: false })
            this.loadPage()
            } 
        catch (error) {
            Bugsnag.notify(error);
            toaster.danger('Operation failed: ' + error, {duration: 5})
        }
    }

    saveSettings = async (event) => {
        // Make sure all fields are filled out and unique (as needed)
        if (event === "supressMessageAndEnable") {
            this.setState({ enablingWishWell: true })
        }
        if (event === "dontSupress" || event === "supressMessageAndEnable") {
            event !== "supressMessageAndEnable" && this.setState({ savingSettings: true })
            if (!this.state.settings.chapterName) {
                toaster.warning('Please enter an organization name.', {duration: 5})
                this.setState({ selectedIndex: 0, savingSettings: false, enablingWishWell: false })
                return
            }
            if (this.state.settings.minWishPrice < 1) {
                toaster.warning('The minimum donation per wish is ' + this.state.currencySymbol + '1', {duration: 5})
                this.setState({ selectedIndex: 0, savingSettings: false, enablingWishWell: false })
                return
            }
        }
        try {

            var SETTINGS = this.state.settings
            
            //Edit existing settings record
            // Ensure makeawishurl is unique
            var makeAWishURL = this.state.settings.makeAWishURL.toLowerCase()
            var tempMakeAWishURL = makeAWishURL
            var counter = 0
            var mawURLUnique = true
            var mawSettings = await API.graphql(graphqlOperation(settingsByMakeAWishURL, { makeAWishURL: tempMakeAWishURL }))
            while (mawSettings.data.settingsByMakeAWishURL.items.length > 0 && this.state.originalMakeAWishURL !== tempMakeAWishURL) {
                mawURLUnique = false
                tempMakeAWishURL = makeAWishURL + counter
                mawSettings = await API.graphql(graphqlOperation(settingsByMakeAWishURL, { makeAWishURL: tempMakeAWishURL }))
                counter++
            }
            makeAWishURL = tempMakeAWishURL
            if (!mawURLUnique) {
                toaster.warning('The Custom URL you entered is already in use. Please enter a unique Make-A-Wish URL. ' + makeAWishURL + ' is one available option.', {duration: 5})
                this.setState({ selectedIndex: 2 })
                return
            }
            if (this.state.settings.makeAWishURL.length > 22) {
                toaster.warning('The Custom URL you entered is too long. It must be 22 characters or less.', {duration: 5})
                this.setState({ selectedIndex: 2 })
                return
            }
            const updatedSettings = this.state.settings
            updatedSettings.id = SETTINGS.id
            updatedSettings.envelopeReturnName = this.state.settings.chapterName
            updatedSettings.makeAWishURL = this.state.settings.makeAWishURL.toLowerCase()
            this.setState({
                settings: updatedSettings
            })
            var settingsToUpdate = updatedSettings
            // delete settingsToUpdate.bankInfo;
            var updatedOrganization = {}
            updatedOrganization.id = this.state.organization.id
            updatedOrganization.name = updatedSettings.chapterName
            try {
                await API.graphql(graphqlOperation(updateOrganization, {input: updatedOrganization}))
                await API.graphql(graphqlOperation(updateSetting, {input: settingsToUpdate}))
                if (event === "dontSupress" || event === "supressMessageAndEnable") {
                    mixpanel.track('Settings updated');
                    event !== "supressMessageAndEnable" && toaster.success('Settings updated!', {duration: 5})
                    await this.loadPage()
                    await this.props.reloadMain()
                    if (!this.state.settings.fullAuto) {
                        this.setState({ enableWishWellModalShown: true })
                    }
                }
                else {
                    event !== "supressMessage" && toaster.success('Settings updated!', {duration: 5})
                    await this.loadPage()
                    await this.props.reloadMain()
                    // await this.loadPage()
                    // await this.props.reloadMain()
                }
                //this.props.changePage("Main")
                } 
            catch (error) {
                // Bugsnag.notify(error);
                // toaster.danger('Operation failed: ' + error, {duration: 5})
            }
        } catch (error) {
            Bugsnag.notify(error);
            toaster.danger('Operation failed: ' + error, {duration: 5})
        }
        this.setState({ 
            savingSettings: false,
            enablingWishWell: false
        })
    }

    toggleFullAuto = async () => {
        var updatedSettings = this.state.settings
        if (this.state.settings.fullAuto) {
            window.notify('To disable WishWell, please contact support.', {duration: 5})
        }
        else {
            if (window.confirm('Are you sure you want to enable WishWell? This will send a series of welcome emails to your members, followed by a quarterly appeal to make wishes, and digital cards on birthdays. We highly recommend importing all of your members before enabling WishWell so that they will get an automated introduction email. Alternatively, you can always send them your custom registration link via email or text.')) {
                if (!this.state.nonProfit && this.state.settings.charityPartner === "") {
                    toaster.warning('As a for-profit organization, you must include your charity partner in order to accept donations. If you are a non-profit, please change your organization type to Nonprofit and try again.', {duration: 5})
                    this.setState({ 
                        enableWishWellLoading: false,
                        enablingWishWell: false
                    })
                    return
                }
                if (!this.state.settings.stripeOnboardingComplete && this.state.organization.parentOrgId !== "859d0bb4-21c4-4c0d-9804-126093d78552") {
                    toaster.warning('Please enter your payout details before enabling WishWell.', {duration: 5})
                    this.setState({ 
                        enableWishWellLoading: false,
                        enablingWishWell: false
                    })
                    return
                }
                if (!this.state.emailVerified) {
                    toaster.warning('Please verify your email address before enabling WishWell.', {duration: 5})
                    this.setState({ 
                        enableWishWellLoading: false,
                        enablingWishWell: false
                    })
                    return
                }
                if (this.state.members.length < 3) {
                    if (!window.confirm('Your organization currently has ' + this.state.members.length + ' active members. We recommend adding at least 3 members before enabling WishWell. Are you sure you wish to continue?', {duration: 5})) {
                        this.setState({ 
                            enableWishWellLoading: false,
                            enablingWishWell: false
                        })
                        return
                    }
                }
                updatedSettings.fullAuto = true
                updatedSettings.fullAutoEnabledDate = new Date().toISOString()
                this.setState({
                    settings: updatedSettings
                })
                await API.graphql(graphqlOperation(updateSetting, {input: updatedSettings}))

                toaster.success('WishWell enabled! Happy Giving!', {duration: 5})
                this.setState({ 
                    enableWishWellModalShown: false, 
                    enableWishWellLoading: false 
                })
                mixpanel.track('WishWell enabled!');
                mixpanel.people.set({
                    'Lifecycle Stage': 'Activated',
                });
                mixpanel.people.set_once({
                    'Activation Date': new Date().toISOString(),
                })
                mixpanel.track('Customer activated!');

                // send email to admin and Ryan
                var message = ''
                if (this.state.nonProfit) {
                    message = '<p>Hey there,</p><p>Congratulations on enabling WishWell! Your donations should start rolling in with the next batch of birthdays. We\'ve just sent out an email to all of the members that you imported when you enabled WishWell, but we know that social media is also an important part of any marketing campaign.</p><p>Here is a draft post that you can update as you deem appropriate that you can share on your social media channels. Your members can register for WishWell in under 2 minutes, so we are hopefull that many more will join the cause over the weeks and months to come.</p><p><h2>Draft Social Media Post</h2></p><p>🎉 BIG NEWS! We\'ve partnered with WishWell to make birthdays more meaningful. Now, every birthday wish comes with a cause. Learn more at <a href="http://wishwell.ai/why" target="_blank" rel="noopener noreferrer" >wishwell.ai/why</a>. And stay tuned for more info! #WishWellImpact"</p><p>More content will follow in the weeks ahead :)</p><p>Happy Giving!</p><p>Your WishWell Team!</p><p><a href="https://www.onegift.ai" target="_blank" rel="noopener noreferrer">Learn more about WishWell and OneGift</a></p>';
                }
                else {
                    message = '<p>Hey there,</p><p>Congratulations on enabling WishWell! The donations for your charity partner should start rolling in with the next batch of birthdays. We\'ve just sent out an email to all of the members that you imported when you enabled WishWell, but we know that social media is also an important part of any marketing campaign.</p><p>Here is a draft post that you can update as you deem appropriate that you can share on your social media channels. Your members can register for WishWell in under 2 minutes, so we are hopefull that many more will join the cause over the weeks and months to come.</p><p><h2>Draft Social Media Post</h2></p><p>🎉 BIG NEWS! We\'ve partnered with WishWell to make birthdays more meaningful. Now, every birthday wish comes with a cause. Learn more at <a href="http://wishwell.ai/why" target="_blank" rel="noopener noreferrer" >wishwell.ai/why</a>. And stay tuned for more info! #WishWellImpact"</p><p>More content will follow in the weeks ahead :)</p><p>If you are planning on matching some of the donations, we suggest marketing that in your social media posts. To do the matching, simply add the amount on top of the incoming donations before you transfer them to your charity partner.</p><p>Happy Giving!</p><p>Your WishWell Team!</p><p><a href="https://www.onegift.ai" target="_blank" rel="noopener noreferrer">Learn more about WishWell and OneGift</a></p>';
                }
                var subject = '🎁😊 Congratulations on enabling WishWell!';
                var params = {
                    to: [this.state.settings.orgEmail],
                    html: message,
                    subject: subject,
                    from: '"WishWell by OneGift" <experience@onegift.ai>'
                };

                // Send email
                const apiName = 'wishwellapi';
                var path = '/wishwellapi/sendEmail';
                var myInit = { // OPTIONAL
                    body: {
                        options: params
                    },
                    headers: {}, // OPTIONAL
                };
                const sendEmail = await API.post(apiName, path, myInit);
                console.log(sendEmail.body)

                // email ryan
                subject = 'New customer enabled WishWell!'
                message = `<p>Customer Name: ${this.state.settings.chapterName}</p><p>Customer Email: ${this.state.settings.orgEmail}</p><p># of Members: ${this.state.members.length}</p><p>Referrer: ${this.state.settings.referrer ? this.state.settings.referrer : ""}</p>`
                params = {
                    to: 'ryan@onegift.ai',
                    html: message,
                    subject: subject,
                    from: '"WishWell by OneGift" <experience@onegift.ai>'
                };
                try {
                    // Send email
                    const apiName = 'wishwellapi';
                    myInit = { // OPTIONAL
                        body: {
                            options: params
                        },
                        headers: {}, // OPTIONAL
                    };
                    const sendEmail = await API.post(apiName, path, myInit);

                    console.log(sendEmail);
                } catch (error) {
                    console.error(error);
                }

                var fromEmail = '"' + this.state.settings.chapterName + '" <' + this.state.settings.orgEmail + '>'
                for (var i = 0; i < this.state.members.length; i++) {
                    if (this.state.members[i].pactive && this.state.members[i].email && this.state.members[i].ecards) {
                        try {
                            var body = {}
                            if (this.state.nonProfit) {
                                body = {
                                    Html: {
                                    Charset: 'UTF-8',
                                    Data: '<p>' + this.state.members[i].fname + ',</p><p>I am so thrilled to announce that ' + this.state.settings.chapterName + ' has partnered with WishWell to make sending wishes and giving donations easier than ever!</p><p>Every quarter, you\'ll get an email with a link to a page where you can search through our members with upcoming birthdays and select those you want to wish well (<a href="http://wishwell.ai/' + this.state.settings.makeAWishURL + '" target="_blank" rel="noopener noreferrer" >wishwell.ai/' + this.state.settings.makeAWishURL + '</a>). Donate ' + this.state.currencySymbol + this.state.settings.minWishPrice + ' per member via credit card, and your friends will get a card on their birthday with your name on it.</p><p>Here\'s a short video demonstration of the process: <a href="http://wishwell.ai/wishestutorial" target="_blank" rel="noreferrer">wishwell.ai/wishestutorial</a></p><p>WishWell was built by OneGift, and, like us they are committed to fostering the spirit of generosity and community. Head over to <a href="https://www.onegift.ai" target="_blank" rel="noopener noreferrer" >onegift.ai</a> to learn more!</p><p>If you have any questions or you don\'t want to receive these emails, you can reply to this email or contact experience@onegift.ai.</p><p>Happy Giving!</p><p>' + this.state.settings.chapterName + '</p>',
                                    },
                                }
                            }
                            else {
                                body = {
                                    Html: {
                                    Charset: 'UTF-8',
                                    Data: '<p>' + this.state.members[i].fname + ',</p><p>I am so thrilled to announce that ' + this.state.settings.chapterName + ' has partnered with WishWell to make sending wishes and giving donations to our partner charity, ' + this.state.settings.charityPartner + ', easier than ever!</p><p>Every quarter, you\'ll get an email with a link to a page where you can search through our members with upcoming birthdays and select those you want to wish well (<a href="http://wishwell.ai/' + this.state.settings.makeAWishURL + '" target="_blank" rel="noopener noreferrer" >wishwell.ai/' + this.state.settings.makeAWishURL + '</a>). Donate ' + this.state.currencySymbol + this.state.settings.minWishPrice + ' per member via credit card, and your friends will get a card on their birthday with your name on it.</p><p>Here\'s a short video demonstration of the process: <a href="http://wishwell.ai/wishestutorial" target="_blank" rel="noreferrer">wishwell.ai/wishestutorial</a></p><p>WishWell was built by OneGift, and, like us they are committed to fostering the spirit of generosity and community. Head over to <a href="https://www.onegift.ai" target="_blank" rel="noopener noreferrer" >onegift.ai</a> to learn more!</p><p>If you have any questions or you don\'t want to receive these emails, you can reply to this email or contact experience@onegift.ai.</p><p>Happy Giving!</p><p>' + this.state.settings.chapterName + '</p>',
                                    },
                                }
                            }
                            const options = {
                                from: fromEmail,
                                to: [this.state.members[i].email],
                                html: body,
                                subject: '🎁😊 ' + this.state.settings.chapterName + ' has partnered with WishWell!',
                            };

                            // Send email call to api
                            const apiName = 'wishwellapi';
                            path = '/wishwellapi/sendEmail';
                            myInit = { // OPTIONAL
                                body: {
                                    options: options
                                },
                                headers: {}, // OPTIONAL
                            };
                            await API.post(apiName, path, myInit)
                            
                        } catch (error) {
                            Bugsnag.notify(error);
                            toaster.warning('Members couldn\'t be emailed. Please try again or contact support.', {duration: 5})
                        }
                    };
                }
            }
        }
    }

    generatePassword(length) {
        const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let password = "";
        for (let i = 0; i < length; i++) {
          password += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return password;
    }

    // SCRIPT: delete dummy users made via demos (make sure to comment out delete user button)
    deleteDummyUser = async (userId) => {
        try {
            const user_record = await API.graphql(graphqlOperation(userByUserName, { userName: userId }))
            if (user_record.data.userByUserName.items.length !== 0) {
                const user = user_record.data.userByUserName.items[0]
                const orgId = user.organizationId
                const settings_record = await API.graphql(graphqlOperation(settingsByOrganizationId, { organizationId: orgId }))
                const settings = settings_record.data.settingsByOrganizationId.items[0]

                // Delete the user, organization, and settings records
                await API.graphql(graphqlOperation(deleteUser, { input: { id: user.id } }));
                // await API.graphql(graphqlOperation(deleteOrganization, { input: { id: orgId } }));
                // await API.graphql(graphqlOperation(deleteSetting, { input: { id: settings.id } }));
                console.log(`User ${userId} has been deleted from user, organization, and settings tables. You need to manually delete the org and settings if this is a dummy org.`)
            }
            else {
                console.log(`User ${userId} was not yet in the user, organization, or settings tables.`)
            }
        
            // Delete the user from Cognito
            // try {
            //     const cognitoUser = await Auth.deleteUser(userId);
            //     console.log(`User ${userId} has been deleted from cognito.`);
            // } catch (error) {
            //     if (error.code === 'UserNotFoundException') {
            //         console.log(`User ${userId} was not found in cognito.`);
            //     } else {
            //         console.error(error);
            //     }
            // }
        
            // Remove the user from HubSpot - not needed for now
            // const apiKey = 'pat-na1-b30a09ff-818c-4520-89d6-d01471f578c8';
            // const email = userId
            // const authResponse = await axios.post(`https://api.hubapi.com/oauth/v1/token?grant_type=client_credentials&client_id=${apiKey}&client_secret=${apiKey}`);
            // const accessToken = authResponse.data.access_token;
            // const searchResponse = await axios.get(`https://api.hubapi.com/contacts/v1/search/query?q=${email}&hapikey=${apiKey}&access_token=${accessToken}`);
            // const contact = searchResponse.data.contacts[0];
        
            // if (contact) {
            //     await axios.delete(`https://api.hubapi.com/contacts/v1/contact/vid/${contact.vid}?hapikey=${apiKey}&access_token=${accessToken}`);
            //     console.log(`User ${userId} has been deleted from hubspot.`);
            // }
            // else {
            //     console.log(`User ${userId} not found in hubspot.`);
            // }

            console.log(`User ${userId} has been deleted completely.`);
        
        } catch (error) {
            console.error(error);
        }
    
    }

    changeOrg = async () => {
        // Load all orgs and set state.allOrgs
        try {
            var nextToken = null;
            var count = 0
            var filteredList
            var ORGS = [{name: 'Select an organization', ID: ''}]
            
            //TODO: change to get query rather than fliterd list query
            while (nextToken || count === 0) {
                count = 1
                filteredList = await API.graphql(
                graphqlOperation(listOrganizations, {
                    limit: 900,
                    nextToken:nextToken
                }))
                nextToken = filteredList.data.listOrganizations.nextToken
                ORGS = ORGS.concat(filteredList.data.listOrganizations.items)
            }
            ORGS.sort(function(a, b) {
                var textA = a.name ? a.name.toUpperCase() : '';
                var textB = b.name ? b.name.toUpperCase() : '';
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            this.setState({ allOrgs: ORGS })
        } catch (error) {
            Bugsnag.notify(error);
            console.log(error)
        }
    }

    changeOrgSave = async (orgName) => {
        // Set organization ID for user 'ryan@onegift.ai' to the orgId of the passed org value
        try {
            // get org from this.state.allOrgs given org.name
            const org = this.state.allOrgs.find(o => o.name === orgName);
            var updatedUser = {}
            updatedUser.id = this.state.user.id
            updatedUser.organizationId = org.id
            // update user
            await API.graphql(graphqlOperation(updateUser, {input: updatedUser}))
            this.loadPage()
            this.props.reloadMain();
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        if (this.state.settingsLoaded) {
            return (
                <div  style={{ marginTop: '10px' }}>
                <Pane height={240}>
                    <Tablist marginBottom={16} flexBasis={240} marginRight={24}>
                        {this.tabs.map((tab, index) => {
                        return (
                            <Tab
                            aria-controls={`panel-${tab}`}
                            direction="vertical"
                            isSelected={index === this.state.selectedIndex}
                            key={tab}
                            onSelect={() => this.setState({selectedIndex: index})}
                            >
                            {tab}
                            </Tab>
                        )
                        })}
                    </Tablist>
                    <Pane padding={8} background="#fff" flex="1">
                        {this.tabs.map((tab, index) => (
                        <Pane
                            aria-labelledby={tab}
                            aria-hidden={index !== this.state.selectedIndex}
                            display={index === this.state.selectedIndex ? 'block' : 'none'}
                            key={tab}
                            role="tabpanel"
                            >
                            {/* Details Tab */}
                            {tab === 'Organization' && 
                            <div>
                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <h2 style={{ marginRight: '20px' }}>Organization Details</h2>
                                        <Tooltip content="Learn how to enable and configure WishWell in 6 minutes!">
                                            <IconButton icon={HelpIcon} onClick={() => window.open('https://youtu.be/gpRKeglmJsw?si=mDS4qSIerwlWnHEW&t=87', '_blank')}></IconButton>
                                        </Tooltip>
                                    </div>
                                    <table className="responsive-table"><tbody>
                                        <br></br>
                                        <tr>
                                            <td><label htmlFor="chapterName">Organization Name </label></td>
                                            <Tooltip content="Enter your organization name. This will show up on your donor checkout pages and credit card statements.">
                                                <td><TextInput type="text" id="chapterName" name="chapterName" value={this.state.settings.chapterName} onChange={this.handleChange} style={{width: '330px'}}></TextInput></td>
                                            </Tooltip>
                                            <td><label htmlFor="orgEmail">Email </label></td>
                                            <Tooltip content="Enter the email that you want WishWell messages to come from. Make sure to verify this email and save before enabling WishWell.">
                                                <td><TextInput type="email" id="orgEmail" name="orgEmail" placeholder="Where should WishWell emails come from?" value={this.state.settings.orgEmail} onChange={this.handleChange} style={{width: '330px'}}></TextInput></td>
                                            </Tooltip>
                                            {this.state.emailVerified ? 
                                                <td style={{ textAlign: 'left' }}><Button disabled onClick={this.verifyEmail}>Email Verified</Button></td>
                                                :
                                                <td style={{ textAlign: 'left' }}><Button onClick={this.verifyEmail} appearance='primary'>
                                                    {this.state.verifyingEmail && <Spinner size={16} marginRight={8} />}
                                                    Verify Email
                                                </Button></td>
                                            }
                                        </tr>
                                        <tr>
                                            <td><label htmlFor="country">Country </label></td>
                                            <td style={{ textAlign: 'left', width: '330px' }}><Select 
                                                placeholder="country" 
                                                id="country" 
                                                // styles={{ container: (provided) => ({ ...provided, width: 280 }) }}
                                                // isMulti // Allows for selecting multiple countries
                                                value={this.state.settings.country} 
                                                options={this.state.countries}
                                                isDisabled={true}
                                                onChange={(newValue, actionMeta) => this.handleChange(newValue, actionMeta, "country")}/>
                                            </td>
                                            <td><label htmlFor="orgType">Organization Type </label></td>
                                            {/* TODO: fix Select box widths */}
                                            <td style={{ textAlign: 'left', width: '330px' }}><Select 
                                                id="orgType" 
                                                name="orgType"
                                                // isMulti // Allows for selecting multiple countries
                                                value={this.state.settings.orgType} 
                                                options={this.state.orgTypes}
                                                isDisabled={true}
                                                onChange={(newValue, actionMeta) => this.handleChange(newValue, actionMeta, "orgType")}/>
                                            </td>
                                        </tr>
                                        {this.state.organization.parentOrgId === "859d0bb4-21c4-4c0d-9804-126093d78552" && <React.Fragment><tr>
                                            <td><label htmlFor="envelopeReturnAddressLine1">Address Line 1* </label></td>
                                            <td><TextInput placeholder="Street" type="text" id="envelopeReturnAddressLine1" name="envelopeReturnAddressLine1" value={this.state.settings.envelopeReturnAddressLine1} onChange={this.handleChange} style={{width: '330px'}}></TextInput></td>
                                            <td><label htmlFor="envelopeReturnAddressLine2">Address Line 2* </label></td>
                                            <td><TextInput placeholder="City, State, Postcode" type="text" id="envelopeReturnAddressLine2" name="envelopeReturnAddressLine2" value={this.state.settings.envelopeReturnAddressLine2} onChange={this.handleChange} style={{width: '330px'}}></TextInput></td>
                                        </tr></React.Fragment>}
                                        {/* TODO: add in give local and automation checkboxes */}
                                        <tr>
                                            {(!this.state.nonProfit) && <React.Fragment><td><label htmlFor="charityPartner">Charity Partner </label></td>
                                            <td><TextInput type="text" id="charityPartner" name="charityPartner" value={this.state.settings.charityPartner} onChange={this.handleChange}></TextInput></td></React.Fragment>}
                                        </tr>
                                        <tr>
                                            <td><label htmlFor="makeAWishURL">Custom URL </label></td>
                                            <td style={{ textAlign: 'right', width: '330px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                    <Tooltip content="Enter a custom URL for your WishWell page. This will be the URL that you share with your members, and will look like wishwell.ai/custom URL.">
                                                        <TextInput width="200px" type="text" id="makeAWishURL" name="makeAWishURL" value={this.state.settings.makeAWishURL} onChange={this.handleChange} style={{ marginRight: '8px', width: '250px' }}></TextInput>
                                                    </Tooltip>
                                                    <Tooltip content="Open your custom donation page in a new tab.">

                                                        <IconButton icon={ArrowTopRightIcon} onClick={() => window.open(this.state.env === 'dev' ? 'https://www.dev.wishwell.onegift.ai/makeawish?org=' + this.state.settings.makeAWishURL : 'https://wishwell.ai/' + this.state.settings.makeAWishURL, '_blank')} style={{ marginRight: '8px' }} />
                                                    </Tooltip>
                                                    <Tooltip content={`Copy your wishwell donation page url to your clipboard. You can also share a donation link by adding "&donate=true" to the end of the url. More info at onegift.ai/resources/donations.`}>
                                                        <IconButton icon={ClipboardIcon} onClick={() => {
                                                            navigator.clipboard.writeText(this.state.env === 'dev' ? 'https://www.dev.wishwell.onegift.ai/makeawish?org=' + this.state.settings.makeAWishURL : 'https://wishwell.ai/' + this.state.settings.makeAWishURL)
                                                            toaster.success('Copied to clipboard!')
                                                        }} />
                                                    </Tooltip>
                                                </div>
                                            </td>
                                            <td><label htmlFor="brandColor">Brand Color </label></td>
                                            <td><TextInput type="color" id="brandColor" name="brandColor" value={this.state.settings.brandColor} onChange={this.handleChange} style={{width: '330px'}}></TextInput></td>
                                            <td style={{ textAlign: 'left' }}>
                                                {/* The file input field is hidden */}
                                                <input type="file" ref={this.logoInput} style={{ display: 'none' }} onChange={this.uploadLogo} />

                                                {/* The custom upload button */}
                                                <Tooltip content="Logos must be .png or .jpeg file formats.">
                                                    <Button onClick={this.handleUploadButtonClick} appearance='primary' disabled={this.state.uploadingLogo}>
                                                        {this.state.uploadingLogo && <Spinner size={16} marginRight={8} />}
                                                        Upload Logo
                                                    </Button>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label htmlFor="minWishPrice">Minimum Donation/Wish </label></td>
                                            <Tooltip content="Set the minimum donation your members must make for each wish they make.">
                                                <td><TextInput placeholder={"Default is " + this.state.currencySymbol + "1/wish"} type="text" id="minWishPrice" name="minWishPrice" value={this.state.settings.minWishPrice} onChange={this.handleChange} style={{width: '330px'}}></TextInput></td>
                                            </Tooltip>
                                            <td><label htmlFor="defaultWishers">Default Wishers </label></td>
                                            <Tooltip content="Type the wisher's name and press enter to save and add another. You can add as many as you like.">
                                                <td style={{ textAlign: 'left', width: '330px' }}><CreatableSelect placeholder="Default names on all cards with less than 5 wishes" id="defaultWishers" isMulti value={this.state.settings.defaultWishers} onChange={(newValue, actionMeta) => this.handleChange(newValue, actionMeta, "defaultWishers")}/></td>
                                            </Tooltip>
                                        </tr>
                                        <tr>
                                            <td><label htmlFor="events">Events </label></td>
                                            <Tooltip content="Type the event and press enter to save and add another. You can add as many as you like. More events to be added soon!">
                                                <td style={{ textAlign: 'left', width: '330px' }}><CreatableSelect isDisabled id="events" isMulti options={this.state.eventOptions} value={this.state.settings.events} onChange={(newValue, actionMeta) => this.handleChange(newValue, actionMeta, "events")} /></td>
                                            </Tooltip>
                                            {/* TODO: re-enable custom fields */}
                                            {false && <div><td><label htmlFor="customFields">Custom Fields </label></td>
                                            <Tooltip content="Custom fields allow you to store extra data about your donors. Type the field's name and press enter to save and add another. You can add as many as you like.">
                                                <td style={{ textAlign: 'left' }}><CreatableSelect id="customFields" isMulti value={this.state.settings.customFields} onChange={(newValue, actionMeta) => this.handleChange(newValue, actionMeta, "customFields")} /></td></Tooltip></div>}
                                        </tr>
                                    </tbody></table>
                                </div>
                                <div>
                                    <h2>Donor Management</h2>

                                    <div className="responsive-table">
                                        <h4>Active Donors: {this.state.activeCount}</h4>

                                        <CSVLink 
                                            data={this.state.membersForCSV} 
                                            filename={"WishWell Members.csv"}
                                            onClick={() => {
                                                mixpanel.track('Members exported');
                                            }}
                                        >Click me to download a donor import template and/or to export your donor data!</CSVLink>
                                        <br></br>
                                        <br></br>
                                        {/* The file input field is hidden */}
                                        <input type="file" ref={this.membersImport} style={{ display: 'none' }} onChange={this.importMembers} />

                                        {/* The custom upload button */}
                                        <Tooltip content="Please import only .csv files. Donors with unique emails will be added, and those donors with matching emails to existing donors will be updated.">
                                            <Button onClick={this.handleUploadButtonClick} disabled={this.state.importingMembers} appearance='primary' style={{ marginRight: '20px' }}>
                                                {this.state.importingMembers && <Spinner size={16} marginRight={8} />}
                                                Import Donors
                                            </Button>
                                        </Tooltip>
                                        <Button onClick={() => this.setState({ showInviteDonorModal: true })} appearance='primary' intent='success'>
                                            Invite Donors
                                        </Button>
                                        {this.state.importingMembers && <p>{this.state.numberMembersImported} members imported</p>}
                                        <p>Click here for more information on the donor import/export process or to request a data integration: <a href='https://www.onegift.ai/resources/member-imports' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'blue' }}>Imports/Exports Info</a></p>
                                    </div>
                                </div>
                                {this.state.organization.independent && <div>
                                    <h2>Payouts</h2>
                                    <div className="responsive-table">
                                    {!this.state.settings.stripeOnboardingComplete && <h4>Add payout information</h4>}
                                    {this.state.settings.stripeOnboardingComplete && <h4>Update payout information</h4>}
                                        {/* <Button style={{ marginBottom: '10px' }} iconBefore={AddIcon} onClick={() => this.setState({ savePayoutInfoModalShow: true })}>Payout Information</Button> */}
                                        <Tooltip content="You will be redirected to a Stripe page where you can setup/udpate your payout information and view your transactions details.">

                                            <Button style={{ marginBottom: '10px' }} appearance='primary' intent='none' iconBefore={AddIcon} onClick={(action) => this.setupStripe(this.state.settings.stripeOnboardingComplete ? 'update' : 'create')}>
                                                {this.state.setupStripeLoading && <Spinner size={16} marginRight={8} />}
                                                {!this.state.settings.stripeOnboardingComplete ? "Setup Payouts" : "Payouts"}
                                            </Button>
                                        </Tooltip>
                                        <p>Click here for more information on payouts: <a href='https://www.onegift.ai/resources/payouts' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'blue' }}>Payouts Info</a></p>
                                    </div>
                                </div>}
                                <br></br>
                                <br></br>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Button iconBefore={SavedIcon} onClick={() => this.saveSettings('dontSupress')} disabled={this.state.savingSettings} appearance='primary' intent='none' style={{ marginRight: '20px' }}>
                                        {this.state.savingSettings && <Spinner size={16} marginRight={8} />}
                                        Save Settings
                                    </Button>
                                    {/* <Button iconBefore={SavedIcon} onClick={() => this.saveSettings('supressMessageAndEnable')} disabled={this.state.settings.fullAuto} appearance='primary' intent='success'>
                                        {this.state.enablingWishWell && <Spinner size={16} marginRight={8} />}
                                        Enable WishWell
                                    </Button> */}
                                    <Switch 
                                        checked={this.state.settings.birthdayBooster} 
                                        onChange={() => {
                                            this.setState(prevState => ({
                                            settings: {
                                                ...prevState.settings,
                                                birthdayBooster: !prevState.settings.birthdayBooster
                                            }
                                            }), () => this.saveSettings('dontSupress'));
                                        }}
                                        style={{ marginLeft: '10px' }}
                                    >
                                    </Switch> 
                                    <label style={{ marginLeft: '10px' }}>Birthday Booster</label>
                                </div>
                                {(this.state.user.userName === 'ryan@onegift.ai' || this.state.user.userName === 'steina@aaat.com' || this.state.user.userName === 'sam@onegift.ai') && <div>
                                    <button onClick={this.changeOrg}>Change Organization</button>
                                    {this.state.allOrgs && <div><select
                                        id="orgSelect"
                                        name="orgSelect"
                                        value={this.state.selectedOrg}
                                        onChange={(event) => this.setState({ selectedOrg: event.target.value })}
                                        >
                                        {this.state.allOrgs.map((org, index) => (
                                            <option key={index} value={org.name}>
                                            {org.name}
                                            </option>
                                        ))}
                                    </select>
                                    <button onClick={() => this.changeOrgSave(this.state.selectedOrg)}>Save New Organization</button>
                                    </div>}
                                </div>}
                                {this.state.user.userName === 'ryan@onegift.ai' && <React.Fragment><p>
                                    <input type="text" id="dummyUser" name="dummyUser" placeholder="Enter dummy user email" value={this.state.dummyUser} onChange={(event) => this.setState({ dummyUser: event.target.value })}></input>
                                    <button onClick={() => this.deleteDummyUser(this.state.dummyUser)}>Delete dummy user</button>
                                </p></React.Fragment>}
                                <br></br>
                                {this.state.settings.fullAuto && <div><p>WishWell is enabled. Please contact support if you need to disable it.</p></div>}
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                            </div>}
                            {/* Users Tab */}
                            {tab === 'Users' && 
                            <div>
                                <h2>Users</h2>
                                <Button style={{ marginBottom: '10px' }} iconBefore={AddIcon} onClick={() => this.setState({ saveUserModalShown: true })}>Add User</Button>
                                <div style={{ display: "flex", justifyContent: "center" }}><Table width={700}>
                                    <Table.Head>
                                        {/* TODO: add user filtering */}
                                        {/* <Table.SearchHeaderCell onChange={(input) => console.log(input)}/> */}
                                        <Table.TextHeaderCell>username</Table.TextHeaderCell>
                                        <Table.TextHeaderCell>permissions</Table.TextHeaderCell>
                                    </Table.Head>
                                    <Table.Body height={240}>
                                        {this.state.users.map((user) => (
                                        <Table.Row key={user.id} isSelectable onSelect={() => this.setState({ 
                                            saveUserModalShown: true, 
                                            createUpdateUser: 'Update', 
                                            newUser: {
                                                id: user.id,
                                                userName: user.userName,
                                                permissions: user.permissions.map(role => ({ value: role.value, label: role.label })),
                                                NPS: user.NPS,
                                                lifeCycle: user.lifeCycle,
                                                organizationId: user.organizationId,
                                            }
                                        })}>
                                            <Table.TextCell>{user.userName}</Table.TextCell>
                                            <Table.TextCell>{user.permissions.map((role, index) => (role.label + (index < user.permissions.length - 1 ? ", " : "")))}</Table.TextCell>
                                        </Table.Row>
                                        ))}
                                    </Table.Body>
                                    </Table></div>
                                    <p>Click here for more information on user permissions: <a href='https://www.onegift.ai/resources/user-permissions' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'blue' }}>Permissions Info</a></p>
                                </div>}
                            {/* Payouts Tab */}
                            {tab === 'Payouts' && <div></div>}
                            {/* {tab === 'Sub-Orgs' && 
                            <div>
                                Sub-Orgs
                                // Note that create sub-orgs must be !independent and have parent org ID = to creating org
                            </div>} */}
                        </Pane>
                        ))}
                    </Pane>
                </Pane>
                {/* save user modal */}
                <Pane>
                    <Dialog
                    isShown={this.state.saveUserModalShown}
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEscapePress={false}
                    title={this.state.createUpdateUser === "Create" ? "Create User" : "Update User"}
                    isConfirmLoading={this.state.saveUserLoading}
                    onConfirm={() => { this.setState({ saveUserLoading: true }); (this.state.createUpdateUser === "Create" ? this.saveUser("Create") : this.saveUser("Update")) } }
                    confirmLabel={this.state.saveUserLoading ? 'Saving...' : 'Save User'}
                    onCloseComplete={() => this.setState({ 
                        saveUserModalShown: false, 
                        saveUserLoading: false,             
                        newUser: {
                            id: '',
                            userName: '',
                            permissions: [],
                        }, 
                        createUpdateUser: 'Create',
                    })}
                    minHeightContent={350}
                    >
                    <table style={{borderSpacing: '10px'}}><tbody>
                    <tr>
                        <td><label htmlFor="userName">Email </label></td>
                        <td><TextInput disabled={this.state.createUpdateUser === "Update" ? true : false} type="text" id="newUser.userName" name="userName" placeholder="Must be a valid email" value={this.state.newUser.userName} onChange={this.handleChange}></TextInput></td>
                    </tr>
                    <tr>
                        <td><label htmlFor="userPermissions">Permissions </label></td>
                        <td style={{ textAlign: 'left' }}><Select placeholder="Select all permissions" id="user.permissions" name="permissions" isMulti options={this.state.permissionOptions} value={this.state.newUser.permissions} onChange={(newValue, actionMeta) => this.handleChange(newValue, actionMeta, "userPermissions")}/></td>
                    </tr>
                    </tbody></table>
                    <Button appearance="primary" intent="danger" disabled={this.state.createUpdateUser === "Create"} onClick={() => { this.saveUser("Delete") }}>Delete User</Button>
                    </Dialog>
                </Pane>
                {/* TODO: not used now that stripe connect enabled */}
                {/* save payout modal */}
                <Pane>
                    <Dialog
                    isShown={this.state.savePayoutInfoModalShow}
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEscapePress={false}
                    title="Save Payout Info"
                    isConfirmLoading={this.state.savePayoutInfoLoading}
                    onConfirm={() => { this.setState({ savePayoutInfoLoading: true }); this.savePayoutInfo() } }
                    confirmLabel={this.state.savePayoutInfoLoading ? 'Saving...' : 'Save Payout Info'}
                    onCloseComplete={() => this.setState({ savePayoutInfoModalShow: false, savePayoutInfoLoading: false })}
                    minHeightContent={350}
                    >
                    <table style={{borderSpacing: '10px'}}><tbody>
                    <tr>
                        <td><label htmlFor="accountName">Account Name </label></td>
                        <td><TextInput disabled={this.state.payoutFieldsDisabled} type="text" id="bankInfo.accountName" name="accountName" placeholder="Name on the account" value={this.state.settings.bankInfo[0]} onChange={this.handleChange}></TextInput></td>
                    </tr>
                    <tr>
                        <td><label htmlFor="accountNumber">Account Number </label></td>
                        <td><TextInput disabled={this.state.payoutFieldsDisabled} type="text" id="bankInfo.accountNumber" name="accountNumber" placeholder="Name on the account" value={this.state.settings.bankInfo[1]} onChange={this.handleChange}></TextInput></td>
                    </tr>
                    <tr>
                        <td><label htmlFor="routingNumber">Routing Number </label></td>
                        <td><TextInput disabled={this.state.payoutFieldsDisabled} type="text" id="bankInfo.routingNumber" name="routingNumber" placeholder="Routing number or sort code" value={this.state.settings.bankInfo[2]} onChange={this.handleChange}></TextInput></td>
                    </tr>
                    </tbody></table>
                    {this.state.payoutFieldsDisabled && <div><Button appearance="primary" intent="none" onClick={() => { this.setState({ payoutFieldsDisabled: false }) }}>Edit Payout Info</Button></div>}
                    </Dialog>
                </Pane>
                {/* enable wishwell modal */}
                <Pane>
                    <Dialog
                        isShown={this.state.enableWishWellModalShown}
                        title="Enable WishWell"
                        isConfirmLoading={this.state.savePayoutInfoLoading}
                        onConfirm={() => { this.setState({ enableWishWellLoading: true }); this.toggleFullAuto() } }
                        confirmLabel={this.state.enableWishWellLoading ? 'Enabling WishWell...' : 'Enable WishWell'}
                        onCloseComplete={() => this.setState({ enableWishWellModalShown: false, enableWishWellLoading: false, enablingWishWell: false })}
                        minHeightContent={350}
                        >
                        <p>Ready to unlock the full power of WishWell? Click below to enable full automation and begin collecting passive annual recurring donations today! Learn more about <a href='https://onegift.ai/resources/enable-wishwell' target="_blank" rel="noopener noreferrer" >enabling wishwell here</a>.</p>
                        <p>Note that enabling WishWell will also enable WishWell's Donor Marketing Program. See more info here: <a href='https://onegift.ai/resources/donor-marketing-program' target="_blank" rel="noopener noreferrer" >DMP Information</a>.</p>
                        <p>Feel free to <a href='https://onegift.ai/meetings/ryan-walter/demo' target="_blank" rel="noopener noreferrer" >schedule a demo</a> to learn more as well!</p>
                    </Dialog>
                </Pane>
                {/* invite donor modal */}
                <Pane>
                    <Dialog
                        isShown={this.state.showInviteDonorModal}
                        title="Invite your donors to WishWell"
                        onCloseComplete={() => this.setState({ showInviteDonorModal: false })}
                        minHeightContent={350}
                        hasFooter={false}
                        >
                        <p>If you don't want to bulk import your donors, and would rather have them individually opt in to WishWell, you can simply invite them to register on your custom branded WishWell Donor page: <a href={this.state.env === 'dev' ? 'https://www.dev.wishwell.onegift.ai/makeawish?org=' + this.state.settings.makeAWishURL : 'https://wishwell.ai/' + this.state.settings.makeAWishURL} target="_blank" rel="noopener noreferrer" >Registration Page</a>.</p>
                        <p>Registration takes 30 seconds, and is super simple. See more info here: <a href='https://onegift.ai/resources/how-to-make-well-wishes-online' target="_blank" rel="noopener noreferrer" >Donor Registration Information</a>.</p>
                        <p>Simply share the above link however you normally communicate with your donors.</p>
                    </Dialog>
                </Pane>
            </div>
            )
        }
        
        return (
            <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={400}>
                <img src={logo} alt="WishWell by OneGift" style={{ textAlign: "center", paddingTop: '100px', width: '30%', paddingBottom: '20px' }} />
                <div className="progress-bar">
                    <div className="progress-bar-fill"></div>
                </div>
            </Pane>
        )
    }
}

export default Settings