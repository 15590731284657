import React, { useState } from 'react'
import { API, graphqlOperation, Auth } from 'aws-amplify'
import awsconfig from '../aws-exports'
import { createOrder, /*updateOrder,*/ deleteOrder, createTransaction, updateTransaction } from '../graphql/mutations'
import { userByUserName, ordersByDonorPNumber, organizationById, membersByOrganizationId, transactionsByOrganizationId, settingsByOrganizationId } from '../graphql/queries'
// import * as subscriptions from '../graphql/subscriptions';

//UI
import { SelectField, Button, Pane, Dialog, Select, Checkbox, SearchTemplateIcon, TextInputField, toaster } from 'evergreen-ui'

// Bug tracking
import Bugsnag from '@bugsnag/js'

// Analytics
import mixpanel from 'mixpanel-browser';
    
class OrderForm extends React.Component {

    constructor() {
        super()
        const today = new Date();
        const currentMonth = today.getMonth() + 1;
        const currentQuarter = Math.ceil(currentMonth / 3);
        const currentYear = today.getFullYear().toString();
        this.state = {
            donorPNumber: "",
            donor: {},
            check: {
                checkNumber: "",
                checkDate: "",
                checkAmount: ""
            },
            credit: {
                cardName: "",
                cardNumber: "",
                cardExp: "",
                cardCvv: "",
                cardZip: "",
                cardAmount: "",
                cardFees: false
            },
            quarterMonths: [],
            selectedQuarter: "Q" + ((currentQuarter % 4) + 1).toString(),
            selectedYear: currentQuarter === 4 ? (parseInt(currentYear) + 1).toString() : currentYear,
            currentYear: currentQuarter === 4 ? (parseInt(currentYear) + 1).toString() : currentYear,
            eventBasedMemberList: [],
            existingOrders: [],
            donationCount: 0,
            donationsByMonthcount: [0,0,0],
            showPlaceOrdersModal: false,
            saveOrdersLoading: false,
            showingLastYearsOrders: false,
            paymentType: "Check",
            results_1: [],
            results_2: [],
            results_3: [],
            donorName: '',
            isLoading: false,
            eventType: "Birthday",
            selectAll: false,
          }

          // Bindings
          this.componentDidMount = this.componentDidMount.bind(this)
          this.loadPage = this.loadPage.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.showOrderForm = this.showOrderForm.bind(this)
        this.handleKeyPressed = this.handleKeyPressed.bind(this)
        this.saveOrders = this.saveOrders.bind(this)
    }

    // New functions

    componentDidMount() {
        this.donorInput.focus();
        this.loadPage()
        // Tracking
        mixpanel.track('Place orders page visited');
    }

    loadPage = async () => {
        // Check for existing setting record
        const user_info = await Auth.currentUserInfo()
        const username = user_info.username
        const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
        var orgId = user.data.userByUserName.items[0].organizationId
        const settings_record = await API.graphql(graphqlOperation(settingsByOrganizationId, { organizationId: orgId }))
        const orgSettings = settings_record.data.settingsByOrganizationId.items[0]

        this.setState({
            orgSettings: orgSettings
        })
    }

    handleKeyPressed(e) {
        if (e.key === 'Enter') {
            this.showOrderForm();
          }
    }

    handleChange(event) {
        if (event.target) {
            // prevent scroll wheel frm affecting values
            event.target.addEventListener('wheel', (e) => {
                e.preventDefault();
            });
            const target = event.target
            const field = target.name
            const value = target.value
            if (field === 'paymenttype') {
                if (target[0].selected) {
                    this.setState({ paymentType: "Check" })
                }
                else {
                    this.setState({ paymentType: "Credit Card" })
                }
            }
            else if (field === 'donorPNumber') {
                this.setState({donorPNumber: value})
            }
            else if (field.includes('check')) {
                const updatedCheck = this.state.check
                updatedCheck[field] = value
                this.setState({
                    check: updatedCheck
                })
            }
            else if (event.target && event.target.id === 'quarter') {
                this.setState({ selectedQuarter: event.target.value });
                return
            }
            else if (event.target && event.target.id === 'year') {
                this.setState({ selectedYear: event.target.value });
                return
            }
            else if (field.includes('card')) {
                const updatedCredit = this.state.credit
                if (target.type === 'checkbox') {
                    updatedCredit[field] === false ? updatedCredit[field] = true : updatedCredit[field] = false
                }
                else {
                    updatedCredit[field] = value
                }
                this.setState({
                    credit: updatedCredit
                })
            }
            else if (field.includes('selectAll')) {
                var eventBasedMemberList = this.state.eventBasedMemberList
                var newDonationsByMonthCount = this.state.donationsByMonthcount
                if (!this.state.selectAll) {
                    newDonationsByMonthCount.fill(0);
                    for (var i = 0; i < eventBasedMemberList.length; i++) {
                        eventBasedMemberList[i].ordered = true
                        let index = parseInt(this.state.eventBasedMemberList[i].DOB.split('-')[1] - 1)%3
                        newDonationsByMonthCount[index] = newDonationsByMonthCount[index] + 1
                    }
                }
                else {
                    for (i = 0; i < this.state.eventBasedMemberList.length; i++) {
                        eventBasedMemberList[i].ordered = false
                        let index = parseInt(this.state.eventBasedMemberList[i].DOB.split('-')[1] - 1)%3
                        newDonationsByMonthCount[index] = newDonationsByMonthCount[index] - 1
                    }
                }

                const ordered = this.state.selectAll ? false : true
                const updatedResults1 = this.state.results_1.map(item => {
                    return {
                        ...item,
                        props: { 
                            ...item.props,
                            checked: ordered
                        }
                    };
                });
                const updatedResults2 = this.state.results_2.map(item => {
                    return {
                        ...item,
                        props: { 
                            ...item.props,
                            checked: ordered
                        }
                    };
                });
                const updatedResults3 = this.state.results_3.map(item => {
                    return {
                        ...item,
                        props: { 
                            ...item.props,
                            checked: ordered
                        }
                    };
                });

                this.state.results_1 = []
                this.state.results_2 = []
                this.state.results_3 = []

                this.setState({
                    eventBasedMemberList: eventBasedMemberList,
                    donationCount: this.state.selectAll ? 0 : this.state.eventBasedMemberList.length,
                    donationsByMonthcount: newDonationsByMonthCount,
                    selectAll: this.state.selectAll ? false : true,
                    results_1: updatedResults1,
                    results_2: updatedResults2,
                    results_3: updatedResults3
                })
                this.forceUpdate()
            }
        }
        else {
            if (this.state.eventBasedMemberList[event].ordered) {
                this.state.eventBasedMemberList[event].ordered = false
                let newDonationsByMonthCount = this.state.donationsByMonthcount
                let index = parseInt(this.state.eventBasedMemberList[event].DOB.split('-')[1] - 1)%3
                newDonationsByMonthCount[index] = newDonationsByMonthCount[index] - 1

                // Check/uncheck box
                if (index === 0) {
                    var updatedResults = this.state.results_1.map((item, i) => {
                        if (item.props.id === event) {
                            return {
                            ...item,
                            props: {
                                ...item.props,
                                checked: false
                            }
                            };
                        }
                        return item;
                    });
                    this.setState({ results_1: updatedResults })
                }
                else if (index === 1) {
                    updatedResults = this.state.results_2.map((item, i) => {
                        if (item.props.id === event) {
                            return {
                            ...item,
                            props: {
                                ...item.props,
                                checked: false
                            }
                            };
                        }
                        return item;
                    });
                    this.setState({ results_2: updatedResults })
                }
                else if (index === 2) {
                    updatedResults = this.state.results_3.map((item, i) => {
                        if (item.props.id === event) {
                            return {
                            ...item,
                            props: {
                                ...item.props,
                                checked: false
                            }
                            };
                        }
                        return item;
                    });
                    this.setState({ results_3: updatedResults })
                }
                
                this.setState({
                    donationCount: this.state.donationCount - 1,
                    donationsByMonthcount: newDonationsByMonthCount
                })
            }
            else {
                this.state.eventBasedMemberList[event].ordered = true
                let newDonationsByMonthCount = this.state.donationsByMonthcount
                let index = parseInt(this.state.eventBasedMemberList[event].DOB.split('-')[1] - 1)%3
                newDonationsByMonthCount[index] = newDonationsByMonthCount[index] + 1

                // Check/uncheck box
                if (index === 0) {
                    var updatedResults = this.state.results_1.map((item, i) => {
                        if (item.props.id === event) {
                            return {
                            ...item,
                            props: {
                                ...item.props,
                                checked: true
                            }
                            };
                        }
                        return item;
                    });
                    this.setState({ results_1: updatedResults })
                }
                else if (index === 1) {
                    updatedResults = this.state.results_2.map((item, i) => {
                        if (item.props.id === event) {
                            return {
                            ...item,
                            props: {
                                ...item.props,
                                checked: true
                            }
                            };
                        }
                        return item;
                    });
                    this.setState({ results_2: updatedResults })
                }
                else if (index === 2) {
                    updatedResults = this.state.results_3.map((item, i) => {
                        if (item.props.id === event) {
                            return {
                            ...item,
                            props: {
                                ...item.props,
                                checked: true
                            }
                            };
                        }
                        return item;
                    });
                    this.setState({ results_3: updatedResults })
                }
                
                this.setState({
                    donationCount: this.state.donationCount - 1,
                    donationsByMonthcount: newDonationsByMonthCount
                })

                this.setState({
                    donationCount: this.state.donationCount + 1,
                    donationsByMonthcount: newDonationsByMonthCount
                })
            }
        }
    }
    
    // Function: show order form to enter in orders
    showOrderForm = async () => {
        let donor = parseInt(document.getElementById("donorPNumber").value)
        let type = document.getElementById("BorA").value
        let quarter = document.getElementById("quarter").value
        let year = document.getElementById("year").value
        this.setState({
            isLoading: true,
            check: {
                checkNumber: "",
                checkDate: "",
                checkAmount: ""
            },
            credit: {
                cardName: "",
                cardNumber: "",
                cardExp: "",
                cardCvv: "",
                cardZip: "",
                cardAmount: "",
                cardFees: false
            },
            eventBasedMemberList: [],
            existingOrders: [],
            donationCount: 0,
            donationsByMonthcount: [0,0,0],
            showPlaceOrdersModal: false,
            saveOrdersLoading: false,
            showingLastYearsOrders: false,
            paymentType: "Check",
            results_1: [],
            results_2: [],
            results_3: [],
            donorName: '',
            eventType: type,
        })

        // Retrieve active members
        var nextToken = null;
        var count = 0
        var filteredList
        var MEMBERS = []
        const user_info = await Auth.currentUserInfo()
        const username = user_info.username
        const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
        const orgId = user.data.userByUserName.items[0].organizationId

        try {
            let filter = {
                and: [{ pactive: { eq: "true"} },
                    //   { pforms: { eq: "true"} },
                    { DOB: { ne: "1900-01-01" } },
                    { DOB: { ne: "" } }]
            };
            while (nextToken || count === 0) {
                count = 1
                filteredList = await API.graphql(
                graphqlOperation(membersByOrganizationId, {
                    organizationId: orgId,
                    filter: filter,
                    limit: 900,
                    nextToken:nextToken
                }))
                nextToken = filteredList.data.membersByOrganizationId.nextToken
                MEMBERS = MEMBERS.concat(filteredList.data.membersByOrganizationId.items)
            }

            // sort members
            MEMBERS.sort(function(a, b) {
            var textA = a.lname.toUpperCase();
            var textB = b.lname.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
        } catch (error) {
            console.log(error)
            Bugsnag.notify(error);
        }
        
        const memberCount = MEMBERS.length

        // Set months of quarter
        switch(quarter) {
          case "Q1":
            this.state.quarterMonths = ["January", "February", "March"]
            break;
          case "Q2":
            this.state.quarterMonths = ["April", "May", "June"]
            break;
          case "Q3":
            this.state.quarterMonths = ["July", "August", "September"]
            break;
          case "Q4":
            this.state.quarterMonths = ["October", "November", "December"]
            break;
          default: 
            break;
        }
        
        // Filter birthdays and anniversaries by quarter
        var quarterBirthdays = []
        var quarterAnniversaries = []
        var donorExists = false
        for (var i = 0; i < memberCount; i++) {
            // Set donor name
            if (MEMBERS[i].pnumber === donor) { 
                this.setState({ 
                    donorName: MEMBERS[i].fname + ' ' + MEMBERS[i].lname,
                    donor: MEMBERS[i]
                 })
                donorExists = true
            }
          switch(quarter) {
            case "Q1":
              if (MEMBERS[i].DOB.split("-")[1] >= 1 &&
                  MEMBERS[i].DOB.split("-")[1] <= 3) {
                    if (MEMBERS[i].ptype !== 5) {
                      quarterBirthdays.push(MEMBERS[i])
                    }
                    else {
                      quarterAnniversaries.push(MEMBERS[i])
                    }
                  }
              continue;
            case "Q2":
              if (MEMBERS[i].DOB.split("-")[1] >= 4 &&
                  MEMBERS[i].DOB.split("-")[1] <= 6) {
                    if (MEMBERS[i].ptype !== 5) {
                      quarterBirthdays.push(MEMBERS[i])
                    }
                    else {
                      quarterAnniversaries.push(MEMBERS[i])
                    }
                  }
                continue;
            case "Q3":
              if (MEMBERS[i].DOB.split("-")[1] >= 7 &&
                  MEMBERS[i].DOB.split("-")[1] <= 9) {
                    if (MEMBERS[i].ptype !== 5) {
                      quarterBirthdays.push(MEMBERS[i])
                    }
                    else {
                      quarterAnniversaries.push(MEMBERS[i])
                    }
                  }
                  continue;
            case "Q4":
              if (MEMBERS[i].DOB.split("-")[1] >= 10 &&
                  MEMBERS[i].DOB.split("-")[1] <= 12) {
                    if (MEMBERS[i].ptype !== 5) {
                      quarterBirthdays.push(MEMBERS[i])
                    }
                    else {
                      quarterAnniversaries.push(MEMBERS[i])
                    }
                  }
                  continue;
            default:
              // code block
          }
        }

        if (!donorExists) { 
            toaster.warning("No donor found with that ID. Please try again.", {duration: 5,})
        }

        else {
            let ordersThisYear = false
            let ordersYear = year
            while(!ordersThisYear && (year - ordersYear < 2)) {
                this.state.results_1 = []
                this.state.results_2 = []
                this.state.results_3 = []
                
                // Get orders for year and donor
                nextToken = null;
                count = 0
                var ORDERS = []
                let orderFilter = {
                    and: [{ year: { eq: ordersYear} },
                        { organizationId: {eq: orgId} }]
                };
                while (nextToken || count === 0) {
                    count = 1
                    filteredList = await API.graphql(
                        graphqlOperation(ordersByDonorPNumber, {
                        donorPNumber: donor,
                        filter: orderFilter,
                        limit: 900,
                        nextToken:nextToken
                    })
                    )
                    nextToken = filteredList.data.ordersByDonorPNumber.nextToken
                    ORDERS = ORDERS.concat(filteredList.data.ordersByDonorPNumber.items)
                }

                // Generate table with columns by month, checked if order exists already
                this.state.eventBasedMemberList = type === "Birthday" ? quarterBirthdays : quarterAnniversaries
                // iterate through all members for the quarter
                for (i = 0; i < this.state.eventBasedMemberList.length; i++) {
                    this.state.eventBasedMemberList[i].ordered = false
                    if (parseInt(this.state.eventBasedMemberList[i].DOB.split("-")[1]) % 3 === 1) {
                        for(var j = 0; j < ORDERS.length; j++) {
                            if (ORDERS[j].receiverPNumber === this.state.eventBasedMemberList[i].pnumber) {
                                this.state.eventBasedMemberList[i].ordered = true
                                this.state.existingOrders.push(ORDERS[j])
                                this.state.donationCount++
                                this.state.donationsByMonthcount[0]++
                                ordersThisYear = true
                                break;
                            }
                        }
                        this.state.results_1.push(
                            <OrderItem
                                pnumber={this.state.eventBasedMemberList[i].pnumber}
                                name={this.state.eventBasedMemberList[i].fname + ' ' + this.state.eventBasedMemberList[i].lname}
                                birthday={this.state.eventBasedMemberList[i].DOB.split("-")[2]}
                                checked={this.state.eventBasedMemberList[i].ordered}
                                handleChange={this.handleChange.bind(this)}
                                id={i}
                            />
                        )
                    }
                    if (parseInt(this.state.eventBasedMemberList[i].DOB.split("-")[1]) % 3 === 2) {
                        for(j = 0; j < ORDERS.length; j++) {
                            if (ORDERS[j].receiverPNumber === this.state.eventBasedMemberList[i].pnumber) {
                                this.state.eventBasedMemberList[i].ordered = true
                                this.state.existingOrders.push(ORDERS[j])
                                this.state.donationCount++
                                this.state.donationsByMonthcount[1]++
                                ordersThisYear = true
                                break;
                            }
                        }
                        this.state.results_2.push(
                            <OrderItem
                                pnumber={this.state.eventBasedMemberList[i].pnumber}
                                name={this.state.eventBasedMemberList[i].fname + ' ' + this.state.eventBasedMemberList[i].lname}
                                birthday={this.state.eventBasedMemberList[i].DOB.split("-")[2]}
                                checked={this.state.eventBasedMemberList[i].ordered}
                                handleChange={this.handleChange.bind(this)}
                                id={i}
                            />
                        )
                    }
                    if (parseInt(this.state.eventBasedMemberList[i].DOB.split("-")[1]) % 3 === 0) {
                        for(j = 0; j < ORDERS.length; j++) {
                            if (ORDERS[j].receiverPNumber === this.state.eventBasedMemberList[i].pnumber) {
                                this.state.eventBasedMemberList[i].ordered = true
                                this.state.existingOrders.push(ORDERS[j])
                                this.state.donationCount++
                                this.state.donationsByMonthcount[2]++
                                ordersThisYear = true
                                break;
                            }
                        }
                        this.state.results_3.push(
                            <OrderItem
                                pnumber={this.state.eventBasedMemberList[i].pnumber}
                                name={this.state.eventBasedMemberList[i].fname + ' ' + this.state.eventBasedMemberList[i].lname}
                                birthday={this.state.eventBasedMemberList[i].DOB.split("-")[2]}
                                checked={this.state.eventBasedMemberList[i].ordered}
                                handleChange={this.handleChange.bind(this)}
                                id={i}
                            />
                        )
                    }
                }
                if (ordersThisYear && ordersYear.toString() === year) {
                    toaster.warning(this.state.donorName + " already placed orders for this quarter. Any orders placed will overwrite previously placed orders and will be charged at our regular rate of 5% of donations. If you need to update check information for a previously placed order, we suggest doing so in the exported deposit report.", {duration: 15,})
                }
                if (ordersThisYear && (ordersYear + 1).toString() === year) {
                    this.setState({
                        showingLastYearsOrders: true
                    })
                    toaster.notify("No wishes have been made this year, so we're showing the wishes that were made last year.", {duration: 15,})
                }
                ordersYear = ordersYear - 1
            }
        }
        this.state.isLoading = false
        this.forceUpdate()
    }
    // Function: save new order form
    saveOrders = async () => {
        mixpanel.people.set({
            'Lifecycle Stage': 'Activated',
        });
        mixpanel.people.set_once({
            'Activation Date': new Date().toISOString(),
        })
        mixpanel.track('Customer activated!');
        let donor = parseInt(document.getElementById("donorPNumber").value)
        let quarter = document.getElementById("quarter").value
        let year = document.getElementById("year").value
        let type = document.getElementById("BorA").value
        let currentYear = new Date().getFullYear()
        let currentMonth = new Date().getMonth() + 1

        if ((parseInt(year) === currentYear && ((quarter === "Q1" && currentMonth > 1) || (quarter === "Q2" && currentMonth > 4) || (quarter === "Q3" && currentMonth > 7) || (quarter === "Q4" && currentMonth > 10))) || parseInt(year) < currentYear) {
            if (!window.confirm("You are placing orders for a quarter that has already passed. Press 'OK' to continue or 'Cancel' to make a change.")) {
                this.setState({saveOrdersLoading: false})
                return
            }
        }

        let checkNumber, checkDate, checkAmount
        if (this.state.paymentType === "Check") {
            checkNumber = parseInt(document.getElementById("checkNumber").value)
            checkDate = document.getElementById("checkDate").value
            checkAmount = parseInt(document.getElementById("checkAmount").value)
        }

        // Validate data
        if (checkAmount > 100) {
            if (!window.confirm("You have entered in an amount greater than $100. Press 'OK' to continue or 'Cancel' to make a change.")) {
                this.setState({saveOrdersLoading: false})
                return
            }
        }
        if (parseInt(checkDate.split("-")[0]) < parseInt(year) - 1 || parseInt(checkDate.split("-")[0]) > parseInt(year) + 1) {
            if (!window.confirm("You have entered a check date that is more than one year from the current year. Press 'OK' to continue or 'Cancel' to make a change.")) {
                this.setState({saveOrdersLoading: false})
                return
            }
        }

        // Ensure check information is either all entered or not entered at all
        let has_number = !(isNaN(checkNumber))
        let has_amount = !(isNaN(checkAmount))
        let has_date = !(checkDate === '')
        let check_used = has_number && has_amount && has_date
        // let check_not_used = !has_number && !has_amount && !has_date
        if (!check_used) {
            toaster.warning("Check information is now required. You must enter the information for all of the fields to save, and the check amount should line up with the wishes placed in this quarter.", {duration: 5,})
            this.setState({saveOrdersLoading: false})
            return
        }

        // Create orders
        try {
            // Get orgID
            const user_info = await Auth.currentUserInfo()
            const username = user_info.username
            const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
            const orgId = user.data.userByUserName.items[0].organizationId

            for (var i = 0; i < this.state.eventBasedMemberList.length; i++) {
                // Delete removed/un-checked orders from table
                if (!this.state.eventBasedMemberList[i].ordered) {
                    for (var j = 0; j < this.state.existingOrders.length; j++) {
                        if (this.state.eventBasedMemberList[i].pnumber === this.state.existingOrders[j].receiverPNumber) {
                            await API.graphql(graphqlOperation(deleteOrder, {input: { id: this.state.existingOrders[j].id, }}))
                        }
                    }
                }
                // Add new orders to table
                else if (this.state.eventBasedMemberList[i].ordered) {
                    let orderExists = false
                    if (!this.state.showingLastYearsOrders) {
                        for (j = 0; j < this.state.existingOrders.length; j++) {
                            if (this.state.eventBasedMemberList[i].pnumber === this.state.existingOrders[j].receiverPNumber) {
                                orderExists = true;
                            }
                        }
                    }
                    if (!orderExists) {
                        let newOrder = {
                            donorPNumber: donor,
                            receiverPNumber: this.state.eventBasedMemberList[i].pnumber,
                            year: year,
                            paymentType: this.state.paymentType,
                            checkNumber: checkNumber.toString(),
                            amount: checkAmount,
                            checkDate: checkDate,
                            organizationId: orgId
                        }
                        await API.graphql(graphqlOperation(createOrder, {input: newOrder}))
                    }
                }
            }

            // Check if transaction exists
            var nextToken = null;
            var count = 0
            var filteredList
            var TRANSACTIONS = []
            let filter = {
                and: [{ quarter: { eq: quarter} },
                    { year: { eq: year } },
                    { donorPNumber: { eq: donor } },
                    { paymentType: { eq: this.state.paymentType } }]
            };
            while (nextToken || count === 0) {
                count = 1
                filteredList = await API.graphql(
                graphqlOperation(transactionsByOrganizationId, {
                    organizationId: orgId,
                    filter: filter,
                    limit: 900,
                    nextToken:nextToken
                }))
                nextToken = filteredList.data.transactionsByOrganizationId.nextToken
                TRANSACTIONS = TRANSACTIONS.concat(filteredList.data.transactionsByOrganizationId.items)
            }

            const org = await API.graphql(graphqlOperation(organizationById, { id: orgId }))
            const orgName = org.data.organizationById.items[0].name
            const today = new Date().toISOString()
            const todayDate = today.split('T')[0]
            const receivers = this.state.eventBasedMemberList.filter(res=>res.ordered).map(ele=>ele.pnumber);
            const billAmount = checkAmount > this.state.donationCount * this.state.orgSettings.minWishPrice ? checkAmount : this.state.donationCount * this.state.orgSettings.minWishPrice
            let newTransaction = {
                transactionDate: todayDate,
                quarter: quarter,
                year: year,
                wishType: type,
                donorPNumber: donor,
                donorFName: this.state.donor.fname,
                donorLName: this.state.donor.lname,
                donorEmail: this.state.donor.email,
                donorAddress: this.state.donor.address1,
                donorPhone: this.state.donor.phone,
                receiverPNumbers: receivers,
                amount: billAmount,
                transactionNumber: checkNumber.toString(),
                paymentType: this.state.paymentType,
                organizationId: orgId,
                organizationName: orgName,
            }
            // Remove chance that any legitimate transaction is replaced.
            // if (TRANSACTIONS.length > 0) {
            if (false) {
                // update transaction
                newTransaction.id = TRANSACTIONS[0].id
                API.graphql(graphqlOperation(updateTransaction, {input: newTransaction}))
                // Tracking
                mixpanel.track('Order updated');
            }
            else {
                // create transaction
                await API.graphql(graphqlOperation(createTransaction, {input: newTransaction}))
                // Tracking
                mixpanel.track('Order created');
            }

            toaster.success("Wishes saved!", {duration: 5,})
            this.setState({
                quarterMonths: [],
                eventBasedMemberList: [],
                existingOrders: [],
                showPlaceOrdersModal: false,
                saveOrdersLoading: false,
                showingLastYearsOrders: false,
                results_1: [],
                results_2: [],
                results_3: [],
                donorPNumber: "",
                donor: {},
            check: {
                checkNumber: "",
                checkDate: "",
                checkAmount: ""
            },
            credit: {
                cardName: "",
                cardNumber: "",
                cardExp: "",
                cardCvv: "",
                cardZip: "",
                cardAmount: "",
                cardFees: false
            },
            donationCount: 0,
            donationsByMonthcount: [0,0,0],
            donorName: ''
            })
            this.donorInput.focus();
        } catch (error) {
            Bugsnag.notify(error);
            console.log(error)
        }
    }

    render() {

        return (
            <div>
            <div style={{ display: "flex", justifyContent: "center", textAlign: "center", paddingTop: "20px" }}>
                <table><tbody>
                    <tr>
                    <td>
                        <SelectField width="100px" name="BorA" id="BorA">
                            <option value="Birthday">Birthdays</option>
                            <option value="Anniversary">Anniversaries</option>
                        </SelectField>
                    </td>
                    <td>
                        <SelectField width="140px" name="quarter" id="quarter" value={this.state.selectedQuarter} onChange={this.handleChange}>
                            <option value="Q1">Q1</option>
                            <option value="Q2">Q2</option>
                            <option value="Q3">Q3</option>
                            <option value="Q4">Q4</option>
                        </SelectField>
                    </td>
                    <td>
                        <SelectField width="140px" name="year" id="year" value={this.state.selectedYear} onChange={this.handleChange}>
                            {[...Array(4)].map((_, index) => {
                                const year = this.state.currentYear - 1 + index;
                                return (
                                <option key={year} value={year}>{`${year}`}</option>
                                );
                            })}
                        </SelectField>
                    </td>
                </tr>
                <tr>
                    <td colSpan={3}>
                        <TextInputField inputWidth="200px" label="Donor PAP Number" type="number" id="donorPNumber" name="donorPNumber" value={this.state.donorPNumber} onChange={this.handleChange} onKeyPress={this.handleKeyPressed} ref={inputEl => (this.donorInput = inputEl)}></TextInputField>
                        <Button isLoading={this.state.isLoading} iconBefore={SearchTemplateIcon} type="Button" onClick={() => {this.showOrderForm()}}>Show Order Form</Button>
                    </td>
                    </tr>
                </tbody></table>
            </div>
            <div>
                <br></br>
                {this.state.donorName !== "" && <div><h1 id="donorName">{this.state.donorName} - {this.state.donationCount} Donations</h1></div>}
                <p></p>
                {this.state.donorName !== "" && <div><p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign: 'middle' }}><input type="checkbox" id="selectAll" name="selectAll" checked={this.state.selectAll} onChange={this.handleChange} />&nbsp;Select All</p></div>}
                <table style={{ textAlign: "left", display: "flex", justifyContent: "center", verticalAlign: 'top', borderSpacing: '10px' }}><tbody>
                    <tr style={{ textAlign: "center" }}>
                        <td><h2>{this.state.quarterMonths[0]}{this.state.donorName !== "" && <div>{(this.state.donationsByMonthcount[0])}</div>}</h2></td>
                        <td><h2>{this.state.quarterMonths[1]}{this.state.donorName !== "" && <div>{(this.state.donationsByMonthcount[1])}</div>}</h2></td>
                        <td><h2>{this.state.quarterMonths[2]}{this.state.donorName !== "" && <div>{(this.state.donationsByMonthcount[2])}</div>}</h2></td>
                    </tr>
                    <tr style={{ textAlign: "center", marginTop: "5px" }}>
                        <td><h4>{this.state.eventBasedMemberList.length === 0 ? "" : "ID - Name - " + this.state.eventType}</h4></td>
                        <td><h4>{this.state.eventBasedMemberList.length === 0 ? "" : "ID - Name - " + this.state.eventType}</h4></td>
                        <td><h4>{this.state.eventBasedMemberList.length === 0 ? "" : "ID - Name - " + this.state.eventType}</h4></td>
                    </tr>
                    <tr>
                        <td style={{ verticalAlign: 'top' }}><label htmlFor="ordersPlaced" id="ordersPlaced">{this.state.results_1}</label></td>
                        <td style={{ verticalAlign: 'top' }}><label htmlFor="ordersPlaced" id="ordersPlaced">{this.state.results_2}</label></td>
                        <td style={{ verticalAlign: 'top' }}><label htmlFor="ordersPlaced" id="ordersPlaced">{this.state.results_3}</label></td>
                    </tr>
                </tbody></table>
                {this.state.eventBasedMemberList.length !== 0 && 
                    <Button style={{ marginBottom: '60px' }} onClick={() => this.setState({ showPlaceOrdersModal: true })}>Make Wishes</Button>
                }
            </div>
            <Pane>
                <Dialog
                  isShown={this.state.showPlaceOrdersModal}
                //   isShown={true}
                  shouldCloseOnOverlayClick={false}
                  shouldCloseOnEscapePress={false}
                  title={"Please input payment information for " + this.state.donationCount + ' wishes'}
                  isConfirmLoading={this.state.saveOrdersLoading}
                  onConfirm={() => { this.setState({ saveOrdersLoading: true }); this.saveOrders(); } }
                  confirmLabel={this.state.saveOrdersLoading ? 'Making Wishes...' : 'Make Wishes'}
                  onCloseComplete={() => this.setState({ showPlaceOrdersModal: false, saveOrdersLoading: false })}
                >
                {this.state.paymentType === "Check" && <div>Important: Check information is not required, but if entered, please make sure all information accurate before placing orders to avoid issues with billing or the deposit report. If no amount is entered, default is ${this.state.orgSettings ? this.state.orgSettings.minWishPrice : 1}/wish and can be changed in your Settings page. This action will overwrite any previous orders from this donor for this year.
                <br></br>
                <br></br></div>}
                <label>Payment Type</label>
                <br></br>
                <br></br>
                <Select name="paymenttype" id="paymenttype" onChange={this.handleChange} disabled>
                    <option value={"Check"}>Check</option>
                    <option value={"Credit Card"}>Credit Card</option>
                </Select>
                <br></br>
                <br></br>
                {/* If payment type is check */}
                {this.state.paymentType === "Check" && <div><TextInputField inputWidth="300px" label="Check Number" type="number" id="checkNumber" name="checkNumber" value={this.state.check.checkNumber} onChange={this.handleChange}></TextInputField>
                <TextInputField inputWidth="300px" label="Check Date" type="date" id="checkDate" name="checkDate" value={this.state.check.checkDate} onChange={this.handleChange}></TextInputField>
                <TextInputField inputWidth="300px" label="Check Amount" type="number" id="checkAmount" name="checkAmount" value={this.state.check.checkAmount} onChange={this.handleChange}></TextInputField></div>}
                
                {/* If payment type is credit card */}
                {this.state.paymentType === "Credit Card" && <div>
                    <table><tbody>
                    <tr><TextInputField inputWidth="100px" label="Amount ($)" type="number" id="cardAmount" name="cardAmount" value={this.state.credit.cardAmount} onChange={this.handleChange}></TextInputField></tr>
                        <tr><td><TextInputField inputWidth="150px" label="Name" type="text" id="cardName" name="cardName" value={this.state.credit.cardName} onChange={this.handleChange}></TextInputField></td>
                        <td><TextInputField inputWidth="200px" label="Card Number" type="number" id="cardNumber" name="cardNumber" value={this.state.credit.cardNumber} onChange={this.handleChange}></TextInputField></td></tr>
                        <tr><td><TextInputField inputWidth="100px" label="Card Expiration" type="text" id="cardExp" name="cardExp" placeholder="MM/YY" value={this.state.credit.cardExp} onChange={this.handleChange}></TextInputField></td>
                        <td><TextInputField inputWidth="100px" label="CVV" type="text" id="cardCvv" name="cardCvv" value={this.state.credit.cardCvv} onChange={this.handleChange}></TextInputField></td></tr>
                        <tr><td><TextInputField inputWidth="100px" label="Billing Zipcode" type="text" id="cardZip" name="cardZip" value={this.state.credit.cardZip} onChange={this.handleChange}></TextInputField></td>
                        <td><p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign: 'middle' }}><Checkbox id="cardFees" name="cardFees" checked={this.state.credit.cardFees} onChange={this.handleChange}></Checkbox>&nbsp;I want to cover the transaction fees (7.5%)</p></td></tr>
                    </tbody></table>
                </div>}
                </Dialog>
            </Pane>
        </div>
        )
    }
}

const OrderItem = (props) => {
    return(
        <div>
            <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign: 'middle' }}><input type="checkbox" id="ordered" name="ordered" checked={props.checked} onChange={() => {props.handleChange(props.id)}} /> &nbsp;{props.pnumber} - {props.name} - {props.birthday}</p>
        </div>
    )
}

export default OrderForm